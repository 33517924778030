import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { EMPTY_VALUE } from 'src/constants/constants';
import { copyToClipboard, fNumber, fPercent } from 'src/utils';

import { MarketplaceProjectDetail } from '../../../../../../../../types';

type OfferingSummaryProps = {
  detailData: MarketplaceProjectDetail;
}

export const OfferingSummary = (props: OfferingSummaryProps) => {
  const theme = useTheme();
  const { detailData } = props;

  const publishedStr = detailData.published;
  const editedStr = detailData.lastEdit;

  const onCopy = (str: string) => () => {
    copyToClipboard(str);
  };

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant='h6'>Summary</Typography>
          <Stack gap={2}>
            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Offering Type</Typography>
              <Typography variant='subtitle2'>{detailData.investmentType}</Typography>
            </Stack>

            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Total views</Typography>
              <Typography variant='subtitle2'>{fNumber(detailData.views)}</Typography>
            </Stack>

            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Partner Fee</Typography>
              <Typography variant='subtitle2'>
                {detailData.fee ? fPercent(detailData.fee) : EMPTY_VALUE}
              </Typography>
            </Stack>

            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Published</Typography>
              <Typography variant='subtitle2'>
                {publishedStr}
              </Typography>
            </Stack>

            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Last Edit</Typography>
              <Typography variant='subtitle2'>
                {editedStr}
              </Typography>
            </Stack>

            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Refferal Link</Typography>
              <Typography
                variant='subtitle2'
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={onCopy(detailData.refLink)}
              >
                Copy
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
