import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { debounce } from 'lodash';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { CustomTab, TableCustom, TableFiltersResult, TablePaginationCustom, TabType } from 'src/components';
import { chipVariants, stageColor } from 'src/constants/constants';
import { defaultFilters } from 'src/features/marketplace-details/components/partners-fees/transactions/mock-data';
import { _mockMarketplaceProjectActivity, TABLE_HEAD } from 'src/features/marketplace-details/components/project-details/mock-data';
import { useTable } from 'src/hooks';

import { MarketplaceProjectActivity, MarketplaceProjectActivityStatusType, PaginationParams, PartnerTransactionsTableFilters } from '../../../../../../../../../types';
import { OfferingActivityTableRow } from './offering-activity-table-row';

type OfferingActivityListProps = {
  isPartner?: boolean;
  isPartnerOffering?: boolean;
}

export const OfferingActivityList = (props: OfferingActivityListProps) => {
  const {
    isPartner,
    isPartnerOffering,
  } = props;

  const [filters, setFilters] = useState(defaultFilters);
  const [tableData] = useState<MarketplaceProjectActivity[]>(_mockMarketplaceProjectActivity);
  const dataFiltered = filters.status === 'All' ? tableData : tableData.filter(item => item.status === filters.status);

  const [correspondingBody, setCorrespondingBody] = useState<PaginationParams>({
    page: 1,
    limit: 5,
  });
  const table = useTable();

  const getLenByStatus = useCallback((status: MarketplaceProjectActivityStatusType) => {
    return tableData?.filter((item) => item.status === status)?.length;
  }, [tableData]);

  const TABS: TabType[] = useMemo(() => {
    return [
      {
        value: 'All',
        label: 'All',
        count: tableData?.length,
      },
      {
        value: 'Reserved',
        label: 'Reserved',
        count: getLenByStatus('Reserved'),
      },
      {
        value: 'Commitment Signed',
        label: 'Commitment Signed',
        count: getLenByStatus('Commitment Signed'),
      },
      {
        value: 'Invoice sent',
        label: 'Invoice sent',
        count: getLenByStatus('Invoice sent'),
      },
      {
        value: 'Secured capital',
        label: 'Secured capital',
        count: getLenByStatus('Secured capital'),
      },
    ];
  }, [getLenByStatus, tableData?.length]);

  const handleFilters = useCallback((name: keyof PartnerTransactionsTableFilters, value: string) => {
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, [table]);

  const handleFilterStatus = useCallback((event: SyntheticEvent, newValue: string) => {
    handleFilters('status', newValue as MarketplaceProjectActivityStatusType);
  }, [handleFilters]);

  const canReset = filters.status !== 'All';

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const updatePagination = (newBodyPart: Partial<PaginationParams>) => {
    const newBody: PaginationParams = {
      ...correspondingBody,
      ...newBodyPart,
    };
    setCorrespondingBody(newBody);
  };

  const noDataFound = dataFiltered?.length === 0;

  const onPageChange = debounce((event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    updatePagination({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatePagination({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };
  const onFiltersDeleteHandler = useCallback((filterKey: keyof PartnerTransactionsTableFilters) => {
    const newValue = defaultFilters[filterKey];
    handleFilters(filterKey, newValue);
  }, [handleFilters]);

  return (
    <Card>
      {isPartnerOffering &&
        <CardHeader
          title='Activity'
          sx={{
            px: 3,
            pt: 3,
            pb: 4,
          }}
        />
      }

      {isPartner &&
        <>
          <CustomTab
            tabs={TABS}
            defaultTabValue='All'
            currentTabValue={filters.status}
            handleTabOnChange={handleFilterStatus}
          />
          {canReset && (
            <Stack sx={{
              paddingLeft: '20px',
              paddingBottom: '16px',
            }}>
              <TableFiltersResult
                filters={filters}
                defaultFilters={defaultFilters}
                onFiltersDeleteHandler={onFiltersDeleteHandler}
                onResetFilters={handleResetFilters}
                results={dataFiltered.length}
                chipVariants={chipVariants}
                chipColors={{ ...stageColor }}
              />
            </Stack>
          )}
        </>
      }

      <TableCustom
        headLabels={TABLE_HEAD}
        table={table}
        rowCount={dataFiltered?.length}
        noDataFound={noDataFound}
        bodyCells={(
          <>
            {dataFiltered?.map((row) => (
              <OfferingActivityTableRow
                key={row._id}
                row={row}
              />
            ))}
          </>
        )}
      />

      <TablePaginationCustom
        count={tableData.length}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Card>
  );
};
