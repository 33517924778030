import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useMemo, useState } from 'react';
import { CustomPopover, DrawerCustom, Iconify, MoreOptions, OptionType, UploadedFilesType, UploadFile } from 'src/components';
import { DEFAULT_CURRENCY, marketplaceProjectActivityStatusList } from 'src/constants/constants';
import { UseBooleanReturnType, usePopover } from 'src/hooks';
import { fNumber, fPercent } from 'src/utils';

import { ActivityStatus } from '../../../../../../../../../types';
import { StatusColor } from './status-color';

const statusText: Record<ActivityStatus, string> = {
  'Reserved': 'Reserved',
  'Commitment Signed': 'Soft Commitment Signed',
  'Invoice sent': 'Invoice sent',
  'Secured capital': 'Capital Secured ',
  'Archive': 'Archive',
};

type ActivityDrawerProps = {
  drawer: UseBooleanReturnType;
  status: ActivityStatus;
}

export const ActivityDrawer = (props: ActivityDrawerProps) => {
  const theme = useTheme();
  const textDisabled = theme.palette.text.disabled;
  const textSecondary = theme.palette.text.secondary;
  const { drawer, status: initialStatus } = props;

  const labelSx = useMemo(() => ({
    minWidth: 100, fontWeight: 600, color: textSecondary,
  }), [textSecondary]);

  const statusPopover = usePopover();
  const [status, setStatus] = useState<ActivityStatus>(initialStatus);
  const isReserved = status === 'Reserved';

  const [kycApproved, setKycApproved] = useState<boolean | null>(null);
  const isKycEmpty = kycApproved === null;

  const [email, setEmail] = useState<string>('');
  const [files, setFiles] = useState<UploadedFilesType[]>([]);

  const morePopover = usePopover();
  const moreOptions: OptionType[] = useMemo(() => [], []);

  const onStatusClick = (clickedStatus: ActivityStatus) => () => {
    setStatus(clickedStatus);
    statusPopover.onClose();
  };

  const toggleKycApproved = (value: boolean) => () => {
    setKycApproved(value);
  };

  return (
    <>
      <DrawerCustom
        containerSx={{ width: 480 }}
        open={drawer.value}
        onCloseDrawerHandler={drawer.onFalse}
        headChildren={
          <Stack
            width='100%'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <Stack direction='row' gap={1} alignItems='center'>
              <ButtonBase
                onClick={statusPopover.onOpen}
                sx={{
                  gap: 1,
                  pl: 1,
                  py: 0.5,
                  px: 1,
                  borderRadius: 1,
                  typography: 'subtitle2',
                  bgcolor: 'background.neutral',
                  opacity: isKycEmpty ? 0.5 : 1,
                }}
                disabled={isKycEmpty}
              >
                <StatusColor status={status}/>
                {statusText[status]}
                <Iconify
                  width={18}
                  icon={statusPopover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                />
              </ButtonBase>

              {isKycEmpty && isReserved &&
                  <Stack direction='row' gap={1} alignItems='center'>
                    <Iconify
                      width={16}
                      icon='eva:alert-circle-fill'
                      color={textDisabled}
                    />
                    <Typography variant='caption' color={textSecondary}>
                          Update AML / KYC to change status
                    </Typography>
                  </Stack>
              }
            </Stack>

            <MoreOptions
              options={moreOptions}
              popoverOnClose={morePopover.onClose}
              popoverOnOpen={morePopover.onOpen}
              popoverOpen={morePopover.open}
            />
          </Stack>
        }
        bodyChildren={
          <Stack gap={3}>
            <Typography variant='h6'>Activity Info</Typography>
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography
                variant='caption'
                sx={labelSx}
              >
                Project
              </Typography>
              <Button sx={{ color: theme.palette.primary.main }} variant='text' size='small'>Igelsta
                Strandentré</Button>
            </Stack>
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography
                variant='caption'
                sx={labelSx}
              >
                Investment type
              </Typography>
              <Typography
                variant='subtitle2'
              >
                Debt
              </Typography>
            </Stack>
            <Divider/>
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography
                variant='caption'
                sx={labelSx}
              >
                Investor
              </Typography>
              <Typography
                variant='subtitle2'
              >
                Jane Cooper
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography
                variant='caption'
                sx={labelSx}
              >
                Email
              </Typography>
              <Typography
                variant='subtitle2'
              >
                jane.cooper@gmail.com
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography
                variant='caption'
                sx={labelSx}
              >
                Phone Number
              </Typography>
              <Typography
                variant='subtitle2'
              >
                +46-12-345-6789
              </Typography>
            </Stack>
            <Divider/>
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography
                variant='caption'
                sx={labelSx}
              >
                Amount
              </Typography>
              <Typography
                variant='subtitle2'
              >
                {fNumber(300_000)} {DEFAULT_CURRENCY}
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography
                variant='caption'
                sx={labelSx}
              >
                Commission
              </Typography>
              <Typography
                variant='subtitle2'
              >
                {fNumber(30_000)} {DEFAULT_CURRENCY} ({fPercent(1.4)})
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography
                variant='caption'
                sx={labelSx}
              >
                AML / KYC
              </Typography>
              {isKycEmpty ?
                <Stack direction='row' gap={1}>
                  <Button size='small' variant='outlined' onClick={toggleKycApproved(false)}>
                    <Box
                      sx={{
                        borderRadius: '50%',
                        width: 10,
                        height: 10,
                        mr: 1,
                        background: theme.palette.error.main,
                      }}
                    />
                    Rejected
                  </Button>
                  <Button size='small' variant='outlined' onClick={toggleKycApproved(true)}>
                    <Box
                      sx={{
                        borderRadius: '50%',
                        width: 10,
                        height: 10,
                        mr: 1,
                        background: theme.palette.success.main,
                      }}
                    />
                    Approved
                  </Button>
                </Stack> :
                <Chip
                  variant='soft'
                  size='small'
                  color={kycApproved ? 'success' : 'error'}
                  label={kycApproved ? 'Approved' : 'Rejected'}
                />
              }


            </Stack>
            {!isReserved &&
                <Stack direction='row' alignItems='center' gap={1}>
                  <Typography
                    variant='caption'
                    sx={labelSx}
                  >
                        Commitment
                  </Typography>
                  <Button sx={{ color: theme.palette.primary.main }} variant='text' size='small'>Soft commitment
                        contract.pdf</Button>
                </Stack>
            }
            <Divider/>
            <Stack gap={1.5}>
              <UploadFile
                containerSx={{ width: '100%' }}
                innerContainerSx={{ p: 2.5 }}
                uploadTitle={isReserved ? 'Upload soft commitment contract' : 'Upload Invoice'}
                titleStyleConfig={{
                  variant: 'body2',
                  color: textDisabled,
                  mt: 0.5,
                }}
                uploadedFiles={files}
                deleteFilesHandler={setFiles}
                uploadFileHandler={setFiles}
                fileIconSx={{ mr: 0 }}
                disabled={isKycEmpty && isReserved}
                customIcon={
                  <Iconify
                    icon='eva:cloud-upload-fill'
                    width={40}
                    color={textDisabled}
                  />
                }
              />

              {isKycEmpty && isReserved &&
                  <Stack direction='row' gap={1} alignItems='center'>
                    <Iconify
                      width={16}
                      icon='eva:alert-circle-fill'
                      color={textDisabled}
                    />
                    <Typography variant='caption' color={textSecondary}>
                          Please update AML / KYC to Upload soft commitment
                    </Typography>
                  </Stack>
              }
            </Stack>
          </Stack>
        }
        footerChildrenSx={isReserved ? undefined : { borderTop: `1px solid ${theme.palette.divider}` }}
        footerChildren={
          isReserved ?
            <Button
              variant='contained'
              size='large'
              fullWidth
            >
              Confirm
            </Button> :
            <Stack direction='row' gap={2} alignItems='center' sx={{ flex: 1 }}>
              <TextField
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
                label='* Investor email'
                variant='outlined'
                size='small'
                sx={{ flex: 1 }}
              />
              <Button sx={{ textWrap: 'nowrap' }} size='medium' variant='contained'>Send Invoice</Button>
            </Stack>
        }
      />

      <CustomPopover
        open={statusPopover.open}
        onClose={statusPopover.onClose}
      >
        {marketplaceProjectActivityStatusList.map((s, index) => (
          <MenuItem
            key={index}
            selected={s === status}
            onClick={onStatusClick(s)}
          >
            <Stack direction='row' alignItems='center' gap={1}>
              <StatusColor status={s}/>
              <Typography variant='body2' color='text.primary'>{statusText[s]}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
};
