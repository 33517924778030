import Box from '@mui/material/Box';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { ChangeEvent, memo, useCallback, useState } from 'react';
import { Iconify, Label, Scrollbar, SearchNotFound, useMenuData } from 'src/components';
import { useBoolean, useRouter } from 'src/hooks';

import { ResultItem } from './result-item';
import { applyFilter, getAllItems, groupedData } from './utils';

export const Searchbar = memo(() => {
  const theme = useTheme();
  const router = useRouter();
  const search = useBoolean();
  const navData = useMenuData();

  const [searchQuery, setSearchQuery] = useState('');

  const handleClose = useCallback(() => {
    search.setValue(false);
    setSearchQuery('');
  }, [search]);

  const handleClick = useCallback((path: string) => {
    if (path.includes('http')) {
      window.open(path);
    } else {
      router.push(path);
    }
    handleClose();
  },
  [handleClose, router],
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const dataFiltered = applyFilter({
    inputData: getAllItems({ data: navData }),
    query: searchQuery,
  });

  const notFound = searchQuery && !dataFiltered.length;

  const renderItems = () => {
    const data = groupedData(dataFiltered);

    return Object.keys(data)
      .sort((a, b) => -b.localeCompare(a))
      .map((group, index) => (
        <List key={group || index} disablePadding>
          {data[group].map((item) => {
            const {
              title,
              path,
              disabled,
            } = item;

            const partsTitle = parse(title, match(title, searchQuery));

            const partsPath = parse(path, match(path, searchQuery));

            return (
              <ResultItem
                path={partsPath}
                title={partsTitle}
                disabled={disabled}
                key={`${title}${path}`}
                groupLabel={searchQuery && group}
                onClickItem={() => handleClick(path)}
              />
            );
          })}
        </List>
      ));
  };

  const renderButton = (
    <Stack direction='row' alignItems='center'>
      <IconButton onClick={search.onTrue}>
        <Iconify icon='eva:search-fill'/>
      </IconButton>
    </Stack>
  );

  return (
    <>
      {renderButton}
      {search.value &&
        <Dialog
          fullWidth
          maxWidth='sm'
          open={search.value}
          onClose={handleClose}
          transitionDuration={{
            enter: theme.transitions.duration.shortest,
            exit: 0,
          }}
          PaperProps={{
            sx: {
              mt: 15,
              overflow: 'unset',
            },
          }}
          sx={{ [`& .${dialogClasses.container}`]: { alignItems: 'flex-start' } }}
        >
          <Box sx={{
            p: 3,
            borderBottom: `solid 1px ${theme.palette.divider}`,
          }}>
            <InputBase
              fullWidth
              autoFocus
              placeholder='Search...'
              value={searchQuery}
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify icon='eva:search-fill' width={24} sx={{ color: 'text.disabled' }}/>
                </InputAdornment>
              }
              endAdornment={<Label sx={{
                letterSpacing: 1,
                color: 'text.secondary',
              }}>esc</Label>}
              inputProps={{ sx: { typography: 'h6' } }}
            />
          </Box>

          <Scrollbar sx={{
            p: 3,
            pt: 2,
            height: 400,
          }}>
            {notFound ? <SearchNotFound query={searchQuery} sx={{ py: 10 }}/> : renderItems()}
          </Scrollbar>
        </Dialog>
      }
    </>
  );
});
