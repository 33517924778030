import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';

const UsersPage = lazy(() => import('src/features/users-page'));

const LeadsPage = lazy(() => import('src/features/leads-page'));
const LeadDetails = lazy(() => import('src/features/lead-details'));
const NewLead = lazy(() => import('src/features/new-lead'));

const ResearchPage = lazy(() => import('src/features/research-page'));
const ResearchDetails = lazy(() => import('src/features/research-details'));

const ProjectPreparationPage = lazy(() => import('src/features/project-preparation-page'));
const PreparationDetails = lazy(() => import('src/features/project-preparation-details'));

const TenderingPage = lazy(() => import('src/features/tendering-page'));
const TenderingDetails = lazy(() => import('src/features/tendering-details'));

const FinancingPage = lazy(() => import('src/features/finance-page'));
const FinancingDetails = lazy(() => import('src/features/financing-details'));
// const FinancingDebtRequest = lazy(() => import('src/features/debt-request/financing-debt-request'));
// const DebtFinancingAgreemnt = lazy(() => import('src/features/debt-request/debt-financing-agreement'));
// const UploadFinancingAgreementDocuments = lazy(() => import('src/features/debt-request/upload-financing-agreement-documents'));
const ReadyForInvestorsPage = lazy(() => import('src/features/ready-for-investors-page'));
const ReadyForInvestorsDetails = lazy(() => import('src/features/ready-for-investors-details'));
const EquityDistributionPartners = lazy(() => import('src/features/distribution-partners/equity-distribution-partners'));
const DebtDistributionPartners = lazy(() => import('src/features/distribution-partners/debt-distribution-partners'));
const LoanDistributionPartners = lazy(() => import('src/features/distribution-partners/loan-distribution-partners'));
const MarketplacesPage = lazy(() => import('src/features/marketplaces-page'));
const MarketplaceDetails = lazy(() => import('src/features/marketplace-details'));
const MarketplaceProjectDetailsPage = lazy(() => import('src/features/marketplace-details/components/project-details/project-details-page'));

const InvestorPortfolio = lazy(() => import('src/features/investor-portal/portfolio/portfolio'));
const InvestorPortfolioDetails = lazy(() => import('src/features/investor-portal/portfolio/portfolio-details/portfolio-details'));

const InvestmentActivity = lazy(() => import('src/features/investor-portal/activity/activity'));

const InvestorProjects = lazy(() => import('src/features/investor-portal/projects/projects'));
const InvestorProjectDetails = lazy(() => import('src/features/investor-portal/projects/project-details/project-details'));

const PartnerOverview = lazy(() => import('src/features/partner-portal/overview'));
const PartnerCommissions = lazy(() => import('src/features/partner-portal/commissions'));
const PartnerContacts = lazy(() => import('src/features/partner-portal/contacts'));
const PartnerInvestmentActivity = lazy(() => import('src/features/partner-portal/investment-activity'));
const PartnerProjectsOfferings = lazy(() => import('src/features/partner-portal/projects/offerings'));
const PartnerProjectOpportunitiesPage = lazy(() => import('src/features/partner-portal/projects/opportunities'));
const PartnerProjectOpportunityDetail = lazy(() => import('src/features/partner-portal/projects/opportunities/opportunity-detail'));
const PartnerProjectOpportunityPublish = lazy(() => import('src/features/partner-portal/projects/opportunities/opportunity-publish/opportunity-publish'));
const PartnerProjectClosedDealsPage = lazy(() => import('src/features/partner-portal/projects/closed-deals'));
const PartnerProjectOfferingDetail = lazy(() => import('src/features/partner-portal/projects/partner-project-offering-detail'));
const SuppliersPage = lazy(() => import('src/features/partners-page/suppliers-page'));
const SuppliersProductList = lazy(() => import('src/features/partners-page/components/supplier-product/suppliers-product-list'));
const NewSupplierProduct = lazy(() => import('src/features/partners-page/components/supplier-product/new-supplier-product'));
const EditSupplierProduct = lazy(() => import('src/features/partners-page/components/supplier-product/edit-supplier-product'));

const EquityPage = lazy(() => import('src/features/partners-page/equity-page'));

const BanksPage = lazy(() => import('src/features/partners-page/banks-page'));
const BankProductList = lazy(() => import('src/features/partners-page/components/bank-product/bank-product-list'));
const NewBankProduct = lazy(() => import('src/features/partners-page/components/bank-product/new-bank-product'));
const EditBankProduct = lazy(() => import('src/features/partners-page/components/bank-product/edit-bank-product'));

const ReportDetail = lazy(() => import('src/features/report-detail/report-detail'));
const FinUpraisal = lazy(() => import('src/features/fin-upraisal/fin-upraisal'));

const SellPage = lazy(() => import('src/features/sell-page'));
const SellDetails = lazy(() => import('src/features/sell-details'));

export enum AppRoutes {
  USERS = 'users',

  LEADS = 'leads',
  LEAD_DETAILS = 'leadDetails',
  EDIT_LEAD = 'editLead',
  NEW_LEAD = 'newLead',

  FINANCING = 'financing',
  FINANCING_DETAILS = 'financingDetails',
  // FINANCING_DEBT_REQUEST = 'financingDebtRequest',
  // FINANCING_DEBT_AGREEMENT = 'financingDebtAgreement',
  // UPLOAD_FINANCING_AGREEMENT_DOCUMENTS = 'uploadFinancingAgreementDocuments',

  PREPARATION = 'preparation',
  PREPARATION_DETAILS = 'preparationDetails',

  TENDERING = 'tendering',
  TENDERING_DETAILS = 'tenderingDetails',

  READY_FOR_INVESTORS = 'readyForInvestors',
  READY_FOR_INVESTORS_DETAILS = 'readyForInvestorsDetails',
  INVESTOR_EQUITY_PARTNERS = 'investorEquityPartners',
  DEBT_PARTNERS = 'debtPartners',
  MARKETPLACE_PUBLISH = 'marketplacePublish',

  RESEARCH = 'research',
  RESEARCH_DETAILS = 'researchDetails',

  MARKETPLACES = 'marketplaces',
  MARKETPLACE_DETAILS = 'marketplaceDetails',
  MARKETPLACE_PROJECT_DETAILS = 'marketplaceProjectDetails',

  INVESTOR_PORTFOLIO = 'investorPortfolio',
  INVESTOR_PORTFOLIO_DETAILS = 'investorPortfolioDetails',
  INVESTOR_ACTIVITY = 'investorActivity',
  INVESTOR_PROJECTS = 'investorProjects',
  INVESTOR_PROJECT_DETAILS = 'investorProjectDetails',
  INVESTOR_PROJECT_PREVIEW = 'investorProjectDetailsPreview',

  PARTNER_OVERVIEW = 'partnerOverview',
  PARTNER_COMMISSIONS = 'partnerCommissions',
  PARTNER_CONTACTS = 'partnerContacts',
  PARTNER_INVESTMENT_ACTIVITY = 'partnerInvestmentActivity',
  PARTNER_USERS = 'partnerUsers',
  PARTNER_PROJECT_OPPORTUNITIES = 'partnerProjectOpportunities',
  PARTNER_PROJECT_OPPORTUNITY_DETAILS = 'partnerProjectOpportunityDetails',
  PARTNER_PROJECT_OPPORTUNITY_PUBLISH = 'partnerProjectOpportunityPublish',
  PARTNER_PROJECT_OFFERINGS = 'partnerProjectOfferings',
  PARTNER_PROJECT_OFFERING_DETAILS = 'partnerProjectOfferingsDetails',
  PARTNER_PROJECT_CLOSED_DEALS = 'partnerProjectClosedDeals',

  SUPPLIER_PARTNERS = 'supplierPartners',
  SUPPLIER_PARTNERS_PRODUCTS = 'supplierPartnersProducts',
  EDIT_SUPPLIER_PARTNERS_PRODUCT = 'editSupplierPartnersProduct',
  CREATE_SUPPLIER_PARTNERS_PRODUCT = 'createSupplierPartnersProduct',

  EQUITY_PARTNERS = 'equityPartners',

  BANK_PARTNERS = 'bankPartners',
  BANK_PARTNERS_PRODUCTS = 'bankPartnersProducts',
  EDIT_BANK_PARTNERS_PRODUCT = 'editBankPartnersProduct',
  CREATE_BANK_PARTNERS_PRODUCT = 'createBankPartnersProduct',

  BANK_REPORT_DETAILS = 'bankReportDetails',
  SUPPLIER_REPORT_DETAILS = 'supplierReportDetails',
  SALE_REPORT_DETAILS = 'saleReportDetails',
  CALCULATOR_REPORT_DETAILS = 'calculatorReportDetails',
  FIN_UPRAISAL = 'finUpraisal',

  SELL = 'sell',
  SELL_DETAILS = 'sellDetails',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.USERS]: '/users',

  [AppRoutes.LEADS]: '/opportunities/leads',
  [AppRoutes.LEAD_DETAILS]: '/opportunities/leads/details/:id/:tab',
  [AppRoutes.EDIT_LEAD]: '/opportunities/leads/edit/:id',
  [AppRoutes.NEW_LEAD]: '/opportunities/leads/new-lead',

  [AppRoutes.RESEARCH]: '/opportunities/research',
  [AppRoutes.RESEARCH_DETAILS]: '/opportunities/research/details/:id/:tab',

  [AppRoutes.FINANCING]: '/financing/debt',
  [AppRoutes.FINANCING_DETAILS]: '/financing/debt/details/:id/:tab',

  [AppRoutes.PREPARATION]: '/packaging/project-preparation',
  [AppRoutes.PREPARATION_DETAILS]: '/packaging/project-preparation/details/:id/:tab',

  [AppRoutes.TENDERING]: '/packaging/tendering',
  [AppRoutes.TENDERING_DETAILS]: '/packaging/tendering/details/:id/:tab',

  [AppRoutes.READY_FOR_INVESTORS]: '/financing/equity',
  [AppRoutes.READY_FOR_INVESTORS_DETAILS]: '/financing/equity/details/:id/:tab',
  [AppRoutes.INVESTOR_EQUITY_PARTNERS]: '/financing/equity/equity-partners/:id',
  [AppRoutes.DEBT_PARTNERS]: '/financing/equity/debt-partners/:id',
  [AppRoutes.MARKETPLACE_PUBLISH]: '/financing/equity/marketplace-publish/:id',

  [AppRoutes.MARKETPLACES]: '/marketplaces',
  [AppRoutes.MARKETPLACE_DETAILS]: '/marketplaces/details/:id/:tab',
  [AppRoutes.MARKETPLACE_PROJECT_DETAILS]: '/marketplaces/details/:marketplaceId/projects/:id/:tab',

  [AppRoutes.INVESTOR_PORTFOLIO]: '/investor/portfolio',
  [AppRoutes.INVESTOR_PORTFOLIO_DETAILS]: '/investor/portfolio/:id/:tab',
  [AppRoutes.INVESTOR_ACTIVITY]: '/investor/activity',
  [AppRoutes.INVESTOR_PROJECTS]: '/investor/projects',
  [AppRoutes.INVESTOR_PROJECT_DETAILS]: '/investor/projects/:id/:tab',
  [AppRoutes.INVESTOR_PROJECT_PREVIEW]: '/investor/projects/preview/:tab',

  [AppRoutes.PARTNER_OVERVIEW]: '/partner-portal/overview',
  [AppRoutes.PARTNER_COMMISSIONS]: '/partner-portal/commissions',
  [AppRoutes.PARTNER_CONTACTS]: '/partner-portal/contacts',
  [AppRoutes.PARTNER_INVESTMENT_ACTIVITY]: '/partner-portal/investment-activity',
  [AppRoutes.PARTNER_USERS]: '/partner-portal/users',
  [AppRoutes.PARTNER_PROJECT_OPPORTUNITIES]: '/partner-portal/projects/opportunities',
  [AppRoutes.PARTNER_PROJECT_OPPORTUNITY_PUBLISH]: '/partner-portal/projects/opportunities/:id/publish',
  [AppRoutes.PARTNER_PROJECT_OPPORTUNITY_DETAILS]: '/partner-portal/projects/opportunities/:id/:tab',
  [AppRoutes.PARTNER_PROJECT_OFFERINGS]: '/partner-portal/projects/offerings',
  [AppRoutes.PARTNER_PROJECT_OFFERING_DETAILS]: '/partner-portal/projects/offerings/:id/:tab',
  [AppRoutes.PARTNER_PROJECT_CLOSED_DEALS]: '/partner-portal/projects/deals',
  [AppRoutes.SUPPLIER_PARTNERS]: '/partners/supplier-partners',
  [AppRoutes.SUPPLIER_PARTNERS_PRODUCTS]: '/partners/supplier-partners/:id/products',
  [AppRoutes.EDIT_SUPPLIER_PARTNERS_PRODUCT]: '/partners/supplier-partners/:id/products/:productId/edit',
  [AppRoutes.CREATE_SUPPLIER_PARTNERS_PRODUCT]: '/partners/supplier-partners/:id/products/new',

  [AppRoutes.EQUITY_PARTNERS]: '/partners/equity-partners',

  [AppRoutes.BANK_PARTNERS]: '/partners/bank-partners',
  [AppRoutes.BANK_PARTNERS_PRODUCTS]: '/partners/bank-partners/:id/products',
  [AppRoutes.EDIT_BANK_PARTNERS_PRODUCT]: '/partners/bank-partners/:id/products/:productId/edit',
  [AppRoutes.CREATE_BANK_PARTNERS_PRODUCT]: '/partners/bank-partners/:id/products/new',

  [AppRoutes.BANK_REPORT_DETAILS]: '/report/bank/:id/:identifier',
  [AppRoutes.SUPPLIER_REPORT_DETAILS]: '/report/supplier/:id/:identifier',
  [AppRoutes.SALE_REPORT_DETAILS]: '/report/sale/:id/:identifier',
  [AppRoutes.CALCULATOR_REPORT_DETAILS]: '/report/calculator/:id',
  [AppRoutes.FIN_UPRAISAL]: '/report/fin-upraisal/:id',

  [AppRoutes.SELL]: '/sell',
  [AppRoutes.SELL_DETAILS]: '/sell/details/:id/:tab',
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.USERS]: {
    exact: true,
    path: RoutePath.users,
    component: () => <UsersPage/>,
  },

  [AppRoutes.LEADS]: {
    exact: true,
    path: RoutePath.leads,
    component: () => <LeadsPage/>,
  },

  [AppRoutes.FINANCING]: {
    exact: true,
    path: RoutePath.financing,
    component: () => <FinancingPage/>,
  },
  [AppRoutes.FINANCING_DETAILS]: {
    path: RoutePath.financingDetails,
    component: () => <FinancingDetails/>,
  },
  // [AppRoutes.FINANCING_DEBT_REQUEST]: {
  //   path: RoutePath.financingDebtRequest,
  //   component: () => <FinancingDebtRequest/>,
  // },
  // [AppRoutes.FINANCING_DEBT_AGREEMENT]: {
  //   path: RoutePath.financingDebtAgreement,
  //   component: () => <DebtFinancingAgreemnt/>,
  // },
  // [AppRoutes.UPLOAD_FINANCING_AGREEMENT_DOCUMENTS]: {
  //   path: RoutePath.uploadFinancingAgreementDocuments,
  //   component: () => <UploadFinancingAgreementDocuments/>,
  // },

  [AppRoutes.PREPARATION]: {
    exact: true,
    path: RoutePath.preparation,
    component: () => <ProjectPreparationPage/>,
  },
  [AppRoutes.PREPARATION_DETAILS]: {
    path: RoutePath.preparationDetails,
    component: () => <PreparationDetails/>,
  },
  [AppRoutes.TENDERING]: {
    exact: true,
    path: RoutePath.tendering,
    component: () => <TenderingPage/>,
  },
  [AppRoutes.TENDERING_DETAILS]: {
    path: RoutePath.tenderingDetails,
    component: () => <TenderingDetails/>,
  },

  [AppRoutes.READY_FOR_INVESTORS]: {
    exact: true,
    path: RoutePath.readyForInvestors,
    component: () => <ReadyForInvestorsPage/>,
  },
  [AppRoutes.READY_FOR_INVESTORS_DETAILS]: {
    path: RoutePath.readyForInvestorsDetails,
    component: () => <ReadyForInvestorsDetails/>,
  },
  [AppRoutes.INVESTOR_EQUITY_PARTNERS]: {
    path: RoutePath.investorEquityPartners,
    component: () => <EquityDistributionPartners/>,
  },
  [AppRoutes.DEBT_PARTNERS]: {
    path: RoutePath.debtPartners,
    component: () => <DebtDistributionPartners/>,
  },

  [AppRoutes.MARKETPLACE_PUBLISH]: {
    path: RoutePath.marketplacePublish,
    component: () => <LoanDistributionPartners/>,
  },

  [AppRoutes.RESEARCH]: {
    exact: true,
    path: RoutePath.research,
    component: () => <ResearchPage/>,
  },
  [AppRoutes.RESEARCH_DETAILS]: {
    path: RoutePath.researchDetails,
    component: () => <ResearchDetails/>,
  },

  [AppRoutes.LEAD_DETAILS]: {
    path: RoutePath.leadDetails,
    component: () => <LeadDetails/>,
  },
  [AppRoutes.EDIT_LEAD]: {
    path: RoutePath.editLead,
    component: () => <NewLead/>,
  },
  [AppRoutes.NEW_LEAD]: {
    path: RoutePath.newLead,
    component: () => <NewLead/>,
  },

  [AppRoutes.MARKETPLACES]: {
    exact: true,
    path: RoutePath.marketplaces,
    component: () => <MarketplacesPage/>,
  },
  [AppRoutes.MARKETPLACE_DETAILS]: {
    exact: true,
    path: RoutePath.marketplaceDetails,
    component: () => <MarketplaceDetails/>,
  },
  [AppRoutes.MARKETPLACE_PROJECT_DETAILS]: {
    path: RoutePath.marketplaceProjectDetails,
    component: () => <MarketplaceProjectDetailsPage/>,
  },

  [AppRoutes.INVESTOR_PORTFOLIO]: {
    exact: true,
    path: RoutePath.investorPortfolio,
    component: () => <InvestorPortfolio/>,
  },
  [AppRoutes.INVESTOR_PORTFOLIO_DETAILS]: {
    path: RoutePath.investorPortfolioDetails,
    component: () => <InvestorPortfolioDetails/>,
  },

  [AppRoutes.INVESTOR_ACTIVITY]: {
    path: RoutePath.investorActivity,
    component: () => <InvestmentActivity/>,
  },

  [AppRoutes.INVESTOR_PROJECTS]: {
    exact: true,
    path: RoutePath.investorProjects,
    component: () => <InvestorProjects/>,
  },
  [AppRoutes.INVESTOR_PROJECT_DETAILS]: {
    path: RoutePath.investorProjectDetails,
    component: () => <InvestorProjectDetails/>,
  },
  [AppRoutes.INVESTOR_PROJECT_PREVIEW]: {
    path: RoutePath.investorProjectDetailsPreview,
    component: () => <InvestorProjectDetails/>,
  },

  [AppRoutes.SUPPLIER_PARTNERS]: {
    exact: true,
    path: RoutePath.supplierPartners,
    component: () => <SuppliersPage/>,
  },
  [AppRoutes.EDIT_SUPPLIER_PARTNERS_PRODUCT]: {
    path: RoutePath.editSupplierPartnersProduct,
    component: () => <EditSupplierProduct/>,
  },
  [AppRoutes.CREATE_SUPPLIER_PARTNERS_PRODUCT]: {
    path: RoutePath.createSupplierPartnersProduct,
    component: () => <NewSupplierProduct/>,
  },
  [AppRoutes.SUPPLIER_PARTNERS_PRODUCTS]: {
    path: RoutePath.supplierPartnersProducts,
    component: () => <SuppliersProductList/>,
  },

  [AppRoutes.EQUITY_PARTNERS]: {
    exact: true,
    path: RoutePath.equityPartners,
    component: () => <EquityPage/>,
  },

  [AppRoutes.BANK_PARTNERS]: {
    exact: true,
    path: RoutePath.bankPartners,
    component: () => <BanksPage/>,
  },
  [AppRoutes.EDIT_BANK_PARTNERS_PRODUCT]: {
    path: RoutePath.editBankPartnersProduct,
    component: () => <EditBankProduct/>,
  },
  [AppRoutes.CREATE_BANK_PARTNERS_PRODUCT]: {
    path: RoutePath.createBankPartnersProduct,
    component: () => <NewBankProduct/>,
  },
  [AppRoutes.BANK_PARTNERS_PRODUCTS]: {
    path: RoutePath.bankPartnersProducts,
    component: () => <BankProductList/>,
  },

  [AppRoutes.PARTNER_OVERVIEW]: {
    exact: true,
    path: RoutePath.partnerOverview,
    component: () => <PartnerOverview/>,
  },
  [AppRoutes.PARTNER_COMMISSIONS]: {
    exact: true,
    path: RoutePath.partnerCommissions,
    component: () => <PartnerCommissions/>,
  },
  [AppRoutes.PARTNER_CONTACTS]: {
    exact: true,
    path: RoutePath.partnerContacts,
    component: () => <PartnerContacts/>,
  },
  [AppRoutes.PARTNER_INVESTMENT_ACTIVITY]: {
    exact: true,
    path: RoutePath.partnerInvestmentActivity,
    component: () => <PartnerInvestmentActivity/>,
  },
  [AppRoutes.PARTNER_USERS]: {
    exact: true,
    path: RoutePath.partnerUsers,
    component: () => <UsersPage/>,
  },
  [AppRoutes.PARTNER_PROJECT_OPPORTUNITIES]: {
    exact: true,
    path: RoutePath.partnerProjectOpportunities,
    component: () => <PartnerProjectOpportunitiesPage/>,
  },
  [AppRoutes.PARTNER_PROJECT_OPPORTUNITY_PUBLISH]: {
    exact: true,
    path: RoutePath.partnerProjectOpportunityPublish,
    component: () => <PartnerProjectOpportunityPublish/>,
  },
  [AppRoutes.PARTNER_PROJECT_OPPORTUNITY_DETAILS]: {
    path: RoutePath.partnerProjectOpportunityDetails,
    component: () => <PartnerProjectOpportunityDetail/>,
  },
  [AppRoutes.PARTNER_PROJECT_OFFERINGS]: {
    exact: true,
    path: RoutePath.partnerProjectOfferings,
    component: () => <PartnerProjectsOfferings/>,
  },
  [AppRoutes.PARTNER_PROJECT_OFFERING_DETAILS]: {
    path: RoutePath.partnerProjectOfferingsDetails,
    component: () => <PartnerProjectOfferingDetail/>,
  },
  [AppRoutes.PARTNER_PROJECT_CLOSED_DEALS]: {
    exact: true,
    path: RoutePath.partnerProjectClosedDeals,
    component: () => <PartnerProjectClosedDealsPage/>,
  },

  [AppRoutes.BANK_REPORT_DETAILS]: {
    path: RoutePath.bankReportDetails,
    component: () => <ReportDetail type='bank'/>,
  },
  [AppRoutes.SUPPLIER_REPORT_DETAILS]: {
    path: RoutePath.supplierReportDetails,
    component: () => <ReportDetail type='supplier'/>,
  },
  [AppRoutes.SALE_REPORT_DETAILS]: {
    path: RoutePath.saleReportDetails,
    component: () => <ReportDetail type='seller'/>,
  },
  [AppRoutes.CALCULATOR_REPORT_DETAILS]: {
    path: RoutePath.calculatorReportDetails,
    component: () => <ReportDetail type='calculator'/>,
  },
  [AppRoutes.FIN_UPRAISAL]: {
    path: RoutePath.finUpraisal,
    component: () => <FinUpraisal/>,
  },

  [AppRoutes.SELL]: {
    exact: true,
    path: RoutePath.sell,
    component: () => <SellPage/>,
  },
  [AppRoutes.SELL_DETAILS]: {
    path: RoutePath.sellDetails,
    component: () => <SellDetails/>,
  },
};
