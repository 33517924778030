import { demoProject } from 'src/constants/demo-mock-data';

import { Tendering } from '../../../../types';
import { TableHeadType } from '../leads-page';
import { TableFilters } from './types';

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    label: 'Name / Type',
    width: 110,
  },
  {
    _id: '',
    label: 'GDV / Project size',
    width: 110,
    align: 'right',
  },
  {
    _id: '',
    label: 'Build Costs / psqm',
    width: 130,
    align: 'right',
  },
  {
    _id: 'lastActivity',
    _demoId: 'timeline.message',
    label: 'Last Activity',
    width: 210,
  },
  {
    _id: '',
    label: 'Status',
    width: 110,
  },
  {
    _id: 'controls',
    width: 10,
  },
];

export const defaultFilters: TableFilters = {
  text: '',
  projectType: [],
  stage: 'All',
};

export const _tenderingItems: Tendering[] = [
  {
    _id: '1',
    project: {
      ...demoProject,
      name: 'Igelsta Strandentré',
      scenario: {
        ...demoProject.scenario!,
        calculate: {
          ...demoProject.scenario?.calculate,
          output: {
            ...demoProject.scenario?.calculate.output,
            GDV: 128_700_000,
          },
          input: {
            ...demoProject.scenario?.calculate.input,
            total_floor_area: 2340,
            total_construction_cost: 72_251_107,
            psqm: 30_877,
          },
        },
      },
    },
    status: 'Lead',
    timeline: {
      message: '3 requests sent',
      date: '2 days ago',
    },
  },
  {
    _id: '2',
    project: {
      ...demoProject,
      name: 'Gökegårds Tun',
      scenario: {
        ...demoProject.scenario!,
        calculate: {
          ...demoProject.scenario?.calculate,
          output: {
            ...demoProject.scenario?.calculate.output,
            GDV: 181_150_000,
          },
          input: {
            ...demoProject.scenario?.calculate.input,
            total_floor_area: 3868,
            total_construction_cost: 91_408_101,
            psqm: 28_500,
          },
        },
      },
    },
    status: 'In Progress',
    timeline: {
      message: 'Preliminary quotation added',
      date: '2 days ago',
    },
  },
  {
    _id: '3',
    project: {
      ...demoProject,
      name: 'Igelsta Loft',
      scenario: {
        ...demoProject.scenario!,
        calculate: {
          ...demoProject.scenario?.calculate,
          output: {
            ...demoProject.scenario?.calculate.output,
            GDV: 61_950_000,
          },
          input: {
            ...demoProject.scenario?.calculate.input,
            total_floor_area: 1782,
            total_construction_cost: 49_840_725,
            psqm: 28_000,
          },
        },
      },
    },
    status: 'In Progress',
    timeline: {
      message: 'Itemized quotation requested',
      date: '2 days ago',
    },
  },
  {
    _id: '4',
    project: {
      ...demoProject,
      name: 'Älvsjö Loft',
      scenario: {
        ...demoProject.scenario!,
        calculate: {
          ...demoProject.scenario?.calculate,
          output: {
            ...demoProject.scenario?.calculate.output,
            GDV: 432_160_000,
          },
          input: {
            ...demoProject.scenario?.calculate.input,
            total_floor_area: 3568,
            total_construction_cost: 99_904_725,
            psqm: 28_000,
          },
        },
      },
    },
    status: 'In Progress',
    timeline: {
      message: 'Itemized quotation added',
      date: '2 days ago',
    },
  },
  {
    _id: '5',
    project: {
      ...demoProject,
      name: 'Gunhild Ägarlägenheter',
      scenario: {
        ...demoProject.scenario!,
        calculate: {
          ...demoProject.scenario?.calculate,
          output: {
            ...demoProject.scenario?.calculate.output,
            GDV: 76_190_000,
          },
          input: {
            ...demoProject.scenario?.calculate.input,
            total_floor_area: 2868,
            total_construction_cost: 80_304_000,
            psqm: 28_000,
          },
        },
      },
    },
    status: 'Done',
    timeline: {
      message: 'Term Sheet signed',
      date: '2 days ago',
    },
  },
];

export const QUOTATION_REQUESTS_TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    label: 'Supplier',
    width: 250,
  },
  {
    _id: '',
    label: 'Services / Fee',
    width: 160,
  },
  {
    _id: '',
    label: 'Contact Person',
    width: 160,
  },
  {
    _id: '',
    label: 'Email / Phone',
    width: 150,
  },
  {
    _id: '',
    width: 145,
  },
];

export const QUOTATIONS_TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    label: 'Supplier',
    width: 200,
  },
  {
    _id: '',
    label: 'Build Costs / psqm',
    width: 140,
    align: 'right',
  },
  {
    _id: '',
    label: 'Build Time',
    width: 102,
    align: 'right',
  },
  {
    _id: '',
    label: 'Services / Fee',
    width: 160,
  },
  {
    _id: '',
    label: 'Stage',
    width: 170,
  },
  {
    _id: '',
    label: 'Status',
    width: 170,
  },
  {
    _id: '',
    width: 110,
  },
];
