import { Box, Button, Stack } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button';
import { ComponentType, Fragment } from 'react';
import { UNSPECIFIED_VALUE } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';

import { FieldType } from './land-detail';

export interface DetailsListProps<T> {
  fields?: FieldType[];
  wrappedComponent?: ComponentType;
  wrappedProps?: T;
  btnTitle?: string,
  btnConfig?: ButtonProps;
  boxConfig?: BoxProps;
}

export const DetailsList = <T, >(props: DetailsListProps<T>) => {
  const {
    fields = [],
    wrappedProps,
    wrappedComponent: Component = Fragment,
    btnConfig,
    btnTitle,
    boxConfig,
  } = props;
  const mdUp = useResponsive('up', 'md');

  return (
    <Component {...wrappedProps}>
      <Box
        display='flex'
        flexDirection='column'
        gap={2}
        p={3}
        {...boxConfig}
      >
        {fields.map((field, index) => {
          return (
            <Fragment key={index}>
              <Stack direction='row' alignItems='center' justifyContent={mdUp ? 'flex-start' : 'space-between'}>
                <Box
                  component='span'
                  sx={{
                    color: 'text.secondary',
                    width: mdUp ? 240 : 'fit-content',
                  }}
                >
                  {field.label}
                </Box>
                <Box
                  component='span'
                  sx={{
                    color: field.color ?? '#212B36',
                    fontWeight: 600,
                    fontSize: '14px',
                  }}
                >
                  {field.value || UNSPECIFIED_VALUE}
                </Box>
              </Stack>
              {field.subFields?.map((subField, index) => {
                return (
                  <Stack key={index} direction='row' alignItems='center'>
                    <Box component='span' sx={{
                      color: 'text.secondary',
                      width: 240,
                      ml: '10px',
                    }}>
                      {subField.label}
                    </Box>
                    <Box component='span' sx={{
                      color: field.color ?? '#212B36',
                      fontWeight: 600,
                      ml: '-10px',
                    }}>
                      {subField.value}
                    </Box>
                  </Stack>
                );
              })}
            </Fragment>
          );
        })}
      </Box>
      {
        btnTitle &&
          <Button
            variant='outlined'
            sx={{
              mt: 3,
              mr: 3,
            }}
            {...btnConfig}
          >
            {btnTitle}
          </Button>
      }
    </Component>
  );
};
