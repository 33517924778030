import { demoProject } from 'src/constants/demo-mock-data';

import { ReadyForInvestors } from '../../../../types';
import { TableHeadType } from '../leads-page';
import { TableFilters } from './types';

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    _demoId: 'project.name',
    label: 'Name / Type',
    width: 130,
  },
  {
    _id: '',
    _demoId: 'phase',
    label: 'Phase',
    width: 90,
  },
  {
    _id: '',
    _demoId: 'status',
    label: 'Status',
    width: 140,
  },
  {
    _id: '',
    align: 'right',
    label: 'Required / Raised',
    width: 90,
  },
  {
    _id: 'controls',
    width: 50,
  },
];

export const defaultFilters: TableFilters = {
  text: '',
  projectType: [],
  status: 'All',
};

export const _readyForInvestorsItems: ReadyForInvestors[] = [
  {
    _id: '1',
    project: {
      ...demoProject,
      name: 'Igelsta Strandentré',
    },
    phase: 'Packaged',
    status: ['No Shared'],
  },
  {
    _id: '2',
    project: {
      ...demoProject,
      name: 'Gökegårds Tun',
    },
    phase: 'Preliminary',
    status: ['Shared', 'Published'],
    equityDistributionPartnersCount: 5,
    marketPublishesCount: 1,
  },
  {
    _id: '3',
    project: {
      ...demoProject,
      name: 'Igelsta Loft',
    },
    phase: 'Packaged',
    status: ['No Shared'],
  },
  {
    _id: '4',
    project: {
      ...demoProject,
      name: 'Älvsjö Loft',
    },
    phase: 'Preliminary',
    status: ['Published'],
    marketPublishesCount: 5,
  },
  {
    _id: '5',
    project: {
      ...demoProject,
      name: 'Gunhild Ägarlägenheter',
    },
    phase: 'Preliminary',
    status: ['Raised'],
  },
];
