import _ from 'lodash';
import { UNSPECIFIED_VALUE } from 'src/constants/constants';

export const removeSubstrings = (subStrings: string[], str: string, removeFromStartOfSentence = false) => {
  let regexPattern = `(${subStrings.join('|')})`;

  if (removeFromStartOfSentence) {
    regexPattern = `(^|\\.\\s)${regexPattern}`;
  }

  const regex = new RegExp(regexPattern, 'gi');

  return str.replace(regex, '');
};

export const capitalizeFirstLetter = (text: string) => {
  if (!text) return text;
  const trimmedText = text.trim();
  return trimmedText.charAt(0).toUpperCase() + trimmedText.slice(1);
};

export const capitalizeFirstLetters = (text: string) => {
  if (!text) return text;
  const trimmedText = text.trim();
  const words = _.words(trimmedText);
  const formattedWords = _.map(words, word => capitalizeFirstLetter(word));
  return _.join(formattedWords, ' ');
};

export const splitAndCapitalize = (input: string): string => {
  const words = _.words(input);
  const formattedWords = _.map(words, word => _.capitalize(word));
  return _.join(formattedWords, ' ');
};

export const limitLength = (value: string, length: number = 13) => {
  const filteredValue = value.replace(/[^\d.]/g, '');
  return filteredValue.slice(0, length);
};

type ConvertToNumber<T> = {
  [key in keyof T]: number;
};

export const convertToNumber = <T>(obj: T): ConvertToNumber<T> | null => {
  const result = {} as ConvertToNumber<T>;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = (obj[key] || 0).toString();
      const numValue = _.toNumber(_.replace(value, /,/g, ''));
      if (_.isNaN(numValue)) {
        return null;
      } else {
        result[key] = numValue;
      }
    }
  }

  return result;
};

export const showUnspecifiedValue = (value?: string | number) => {
  return value || UNSPECIFIED_VALUE;
};

export const formatFullName = (firstName?: string, lastName?: string) => {
  const parts = _.compact([firstName, lastName]);

  return parts.length > 0 ? parts.join(' ') : UNSPECIFIED_VALUE;
};

export const extractFileExtension = (filename?: string): string => {
  const dotIndex = filename?.lastIndexOf('.');
  return (dotIndex === -1 || dotIndex === 0 ? '' : filename?.slice(dotIndex)) || '';
};

export function onlyNumbers(value: string = '', allowedChars = ''): string {
  const reg = new RegExp(`[^0-9${allowedChars}]`, 'g');
  return value.trim().replace(reg, '');
}

export const handleStringFilter = (value: string | number | undefined): string => value?.toString().trim().toLowerCase() || '';
