import { demoProject, demoScenario } from 'src/constants/demo-mock-data';
import { TenderingBodyParams } from 'src/features/tendering-page';
import { _tenderingItems } from 'src/features/tendering-page/mock-data';

import { DocumentListResponse, QuotationType, TenderingResponse } from '../../../../types';
import { apiFetch } from '../api';
import { setIsLoading } from '../storage';
import { AppThunk } from '../store';
import { deleteTendering, setTenderingList } from './index';
import { CreateTenderingRequestBodyType, TenderingRequestDetails } from './types';

export const loadTenderingList = (paramBody: TenderingBodyParams): AppThunk => async (dispatch, getState) => {
  dispatch(setIsLoading(true));

  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(setTenderingList({
      projects: _tenderingItems,
      counters: {
        All: 0,
        'Lead': 0,
        'In Progress': 0,
        'Done': 0,
      },
      metadata: {
        page: 0,
        total: 0,
      },
    }));
    dispatch(setIsLoading(false));
    return;
  }
  try {
    const tenderingList: TenderingResponse = await apiFetch('tendering/list', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch(setTenderingList(tenderingList));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const deleteTenderingThunk = (id: string): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    dispatch(deleteTendering(id));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const getTenderingRequestDetails = async (projectId: string, isDemo?: boolean): Promise<TenderingRequestDetails | undefined> => {
  if (isDemo) {
    return {
      unitType: demoScenario.calculate.input.units_array!,
      buildTime: 18,
      projectType: 'New Build Apartments',
      totalBoa: 4400,
      location: 'Inre Hollsvattnet 24, 961, 48 Boden',
      buildCostBreakdown: [],
      projectId: demoProject._id,
      scenarioId: demoScenario._id,
      name: demoProject.name,
      constructionCost: demoScenario.calculate.input.construction_costs_array!,
    };
  }
  try {
    const tenderingRequestDetails: TenderingRequestDetails = await apiFetch(`tendering/request-details/${projectId}`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    });
    return tenderingRequestDetails;
  } catch (error) {
    console.error(error);
  }
};


export const createTenderingRequest = (params: CreateTenderingRequestBodyType): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  const {
    scenarioId,
    projectId,
    ...rest
  } = params;
  if (isDemo) {
    return;
  }
  try {
    await apiFetch(`tendering/create-request/${projectId}/${scenarioId}`, {
      method: 'post',
      body: JSON.stringify(rest),
      headers: { 'Content-Type': 'application/json' },
    });

  } catch (error) {
    console.error(error);
  }
};


export const updateQuotation = (quotationId: string, dataToUpdate: Partial<QuotationType>): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    return;
  }

  try {
    await apiFetch(`tendering/quotation-update/${quotationId}`, {
      body: JSON.stringify(dataToUpdate),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  } catch (error) {
    console.error(error);
  }
};


export const deleteQuotation = (quotationId: string): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    return;
  }

  try {
    await apiFetch(`tendering/quotation-delete/${quotationId}`, { method: 'delete' });
  } catch (error) {
    console.error(error);
  }
};

export const getQuotationUploadedFiles = async (quotationId: string, isDemo?: boolean) => {
  if (isDemo) {
    return;
  }
  try {
    const response: DocumentListResponse[] = await apiFetch(`tendering/quotation-files/${quotationId}`, { method: 'get' });
    return response;
  } catch (error) {
    console.error(error);
  }
};
