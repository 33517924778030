import { Chip, ListItemText, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import { memo, MouseEvent } from 'react';
import { ConfirmDialog, Label, ListItemCustom, MoreOptions, OptionType, StyledTableRow } from 'src/components';
import { DEFAULT_CURRENCY, projectsOfferingColor } from 'src/constants/constants';
import { useBoolean, usePopover } from 'src/hooks';
import { fNumber, fPercent, isCLickOnRow } from 'src/utils';

import { ProjectsOffering } from '../../../../../types';

interface LeadsTableRowProps {
  row: ProjectsOffering;
  onViewRow: VoidFunction;
  onDeleteRow: VoidFunction;
  detailsOnClickHandler: VoidFunction;
  showPublishedDate?: boolean;
  showDetailsBtn?: boolean;
  showPartnerFee?: boolean;
}

export const ProjectsOfferingTableRow = memo((props: LeadsTableRowProps) => {
  const {
    row,
    onViewRow,
    detailsOnClickHandler,
    onDeleteRow,
    showPublishedDate,
    showDetailsBtn,
    showPartnerFee,
  } = props;

  const {
    scenario,
    softCommitted,
    raised,
    partnerFee,
    partnerFeePercent,
    required,
    investmentType,
    status,
  } = row;

  const publishedStr = row.published;

  const confirm = useBoolean();
  const popover = usePopover();

  const onUnlistClickHandler = () => {
    onDeleteRow();
    confirm.onFalse();
  };

  const leadMoreOption: OptionType[] = [
    {
      label: 'View',
      icon: 'view',
      closePopover: popover.onClose,
      itemOnClickHandler: onViewRow,
    },
    {
      label: 'Unlist Project',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onViewRow();
  };

  return (
    <>
      <StyledTableRow hover onClick={handleRowOnClick}>
        <TableCell>
          <ListItemCustom
            showAvatar
            avatarSx={{ borderRadius: 1.5 }}
            listItemSx={{ width: 'fit-content' }}
            listItemTextProps={{ primaryTypographyProps: { typography: 'body2', color: 'text.primary' } }}
            secondaryTextTypographySx={{
              component: 'span',
              typography: 'caption',
              color: 'text.secondary',
            }}
            //TODO Change paths
            avatarSrc={scenario?.projectId}
            avatarAlt={scenario?.lead?.location.address}
            primaryText={scenario?.lead?.location.address || 'Ski Resort complex'}
            secondaryText={showPublishedDate
              ? publishedStr
              : scenario?.calculate.output['Project type'] || 'New build houses'}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
          <Typography sx={{ ml: 'auto' }} color='text.primary' variant='body2'>
            {fNumber(softCommitted)} {DEFAULT_CURRENCY}
          </Typography>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
          <ListItemText
            primary={`${fNumber(raised)} ${DEFAULT_CURRENCY}`}
            secondary={`${fNumber(required)} ${DEFAULT_CURRENCY}`}
            primaryTypographyProps={{
              typography: 'body2',
              ml: 'auto',
              color: 'text.primary',
            }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
              typography: 'caption',
              ml: 'auto',
            }}
          />
        </TableCell>

        {showPartnerFee &&
          <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
            <ListItemText
              primary={`${fNumber(partnerFee)} ${DEFAULT_CURRENCY}`}
              secondary={fPercent(partnerFeePercent)}
              primaryTypographyProps={{
                typography: 'body2',
                ml: 'auto',
                color: 'text.primary',
              }}
              secondaryTypographyProps={{
                component: 'span',
                color: 'text.disabled',
                typography: 'caption',
                ml: 'auto',
              }}
            />
          </TableCell>
        }

        <TableCell>
          <Label
            variant='soft'
            color='default'
          >
            {investmentType}
          </Label>
        </TableCell>


        <TableCell>
          <Chip
            size='small'
            variant='outlined'
            color={projectsOfferingColor[status] ?? 'default'}
            label={status}
          />
        </TableCell>
        <TableCell
          align='right'
          size='small'
          padding='none'
          sx={{
            px: 1,
            whiteSpace: 'nowrap',
          }}
        >
          {showDetailsBtn &&
            <Button
              variant='outlined'
              size='small'
              onClick={detailsOnClickHandler}
            >
              Details
            </Button>
          }
          <MoreOptions
            options={leadMoreOption}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        </TableCell>
      </StyledTableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Unlist'
        content='Are you sure want to unlist?'
        action={
          <Button variant='contained' color='error' onClick={onUnlistClickHandler}>
            Unlist
          </Button>
        }
      />
    </>
  );
});
