import { Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import _, { debounce } from 'lodash';
import { ChangeEvent, memo, MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTab, TableCustom, TableFiltersResult, TablePaginationCustom, TableToolbar, TabType } from 'src/components';
import { chipVariants, projectsOfferingColor } from 'src/constants/constants';
import { useTable } from 'src/hooks';
import { AppThunk, RootState } from 'src/store';
import { handleStringFilter, sortCustom } from 'src/utils';

import { PaginationParams, ProjectsOffering, ProjectsOfferingStatusType, ProjectType } from '../../../../types';
import { TableHeadType } from '../leads-page';
import { ProjectsOfferingTableRow } from './components';
import { _investmentList, _projectTypeList, defaultFilters } from './mock-data';
import { TableFilters, TableFilterValue } from './types';

type ProjectsOfferingPageProps = {
  showPublishedDate?: boolean;
  showDetailsBtn?: boolean;
  showPartnerFee?: boolean;
  title?: string;
  offeringList: ProjectsOffering[];
  tableHead: TableHeadType[];
  loadFn: (page: number, records: number) => AppThunk;
  unlistProjectsOffering(projectOfferId: string): AppThunk;
  onDetailPush: (id: string, pathKey?: string) => void;
}

export const ProjectsOfferingPage = memo<ProjectsOfferingPageProps>((props) => {
  const {
    showDetailsBtn,
    showPublishedDate,
    showPartnerFee,
    title,
    offeringList,
    tableHead,
    loadFn,
    unlistProjectsOffering,
    onDetailPush,
  } = props;

  const [, setTableData] = useState<ProjectsOffering[]>(offeringList);

  const [filters, setFilters] = useState(defaultFilters);
  const isDemo = useSelector((state: RootState) => state.App.config?.isDemo);
  const [correspondingBody, setCorrespondingBody] = useState<PaginationParams>({
    page: 1,
    limit: 5,
  });

  const table = useTable();
  const dispatch = useDispatch();

  const dataFiltered: ProjectsOffering[] = applyFilter(sortCustom(offeringList, table), filters);
  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  useEffect(() => {
    dispatch(loadFn(1, 5));
  }, [dispatch, loadFn]);

  useEffect(() => {
    setTableData(offeringList);
  }, [offeringList]);

  const getProjectsOfferingLength = useCallback((offerStatus: ProjectsOfferingStatusType) => {
    const list = offeringList;
    return list?.filter((item) => item.status === offerStatus)?.length;
  }, [offeringList]);

  const TABS: TabType[] = useMemo(() => {
    return [
      {
        value: 'All',
        label: 'All',
        count: offeringList?.length,
      },
      {
        value: 'Ongoing Offerings',
        label: 'Ongoing Offerings',
        count: getProjectsOfferingLength('Ongoing Offerings'),
      },
      {
        value: 'Capital Raised',
        label: 'Capital Raised',
        count: getProjectsOfferingLength('Capital Raised'),
      },
      {
        value: 'Unlisted',
        label: 'Unlisted',
        count: getProjectsOfferingLength('Unlisted'),
      },
    ];
  }, [getProjectsOfferingLength, offeringList?.length]);

  const handleFilters = useCallback((name: keyof TableFilters, value: TableFilterValue) => {
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, [table]);

  const onFiltersDeleteHandler = useCallback((filterKey: keyof TableFilters, valueToDelete: TableFilterValue) => {
    const filterValue = filters[filterKey];
    let newValue = defaultFilters[filterKey];
    if (Array.isArray(filterValue)) {
      filters.projectType.some(item => item === valueToDelete)
        ? newValue = filters.projectType.filter(item => item !== valueToDelete)
        : newValue = filters.investmentType.filter(item => item !== valueToDelete);
    }
    handleFilters(filterKey, newValue);
  }, [filters, handleFilters]);

  const handleFilterOfferStatus = useCallback((_: SyntheticEvent, newValue: string) => {
    handleFilters('offerStatus', newValue);
  }, [handleFilters]);

  const canReset =
    !!filters.name ||
    filters.investmentType?.length > 0 ||
    filters.projectType?.length > 0 ||
    filters.offerStatus !== 'All';

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const noDataFound = (offeringList?.length === 0 && canReset) || dataFiltered?.length === 0;

  const handleDeleteRow = useCallback((id: string) => () => {
    dispatch(unlistProjectsOffering(id));
    table.onUpdatePageDeleteRow(dataInPage?.length);
  }, [dataInPage?.length, dispatch, table, unlistProjectsOffering]);

  const handleMoveToNextPage = useCallback((id: string, pathKey: string) => () => {
    onDetailPush(id, pathKey);
  }, [onDetailPush]);

  const updateRequestBody = (newBodyPart: Partial<PaginationParams>) => {
    const newBody: PaginationParams = {
      ...correspondingBody,
      ...newBodyPart,
    };
    dispatch(loadFn(newBody.page, newBody.limit));
    setCorrespondingBody(newBody);
  };

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updateRequestBody({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    updateRequestBody({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onDetailsClickHandler = useCallback((id: string) => () => {
    onDetailPush(id);
  }, [onDetailPush]);

  return (
    <>
      {title &&
          <Typography variant='h4' sx={{ mb: 5 }}>
            {title}
          </Typography>
      }

      <Card>
        <CustomTab
          tabs={TABS}
          defaultTabValue='All'
          currentTabValue={filters.offerStatus}
          handleTabOnChange={handleFilterOfferStatus}
        />
        <TableToolbar
          showAdditionalSelect
          selectValue={filters.projectType}
          selectOptions={_projectTypeList}
          selectedOptions={filters.projectType}
          onFilters={handleFilters}
          selectLabel='Project type'
          selectKey='projectType'

          additionalSelectedOptions={filters.investmentType}
          additionalSelectKey='investmentType'
          additionalSelectLabel='Investment type'
          additionalSelectOptions={_investmentList}
          additionalSelectValue={filters.investmentType}

          searchFieldValue={filters.name}
          searchFieldKey='name'
        />
        {canReset && (
          <Stack
            sx={{
              paddingLeft: '20px',
              paddingBottom: '16px',
            }}
          >
            <TableFiltersResult
              filters={filters}
              defaultFilters={defaultFilters}
              onFiltersDeleteHandler={onFiltersDeleteHandler}
              onResetFilters={handleResetFilters}
              results={isDemo ? dataInPage?.length : offeringList.length}
              chipVariants={chipVariants}
              chipColors={{ ...projectsOfferingColor }}
            />
          </Stack>
        )}

        <TableCustom
          headLabels={tableHead}
          table={table}
          rowCount={isDemo ? dataInPage.length : dataFiltered.length}
          noDataFound={noDataFound}
          bodyCells={(
            <>
              {(isDemo ? dataInPage : dataFiltered)?.map((row) => (
                <ProjectsOfferingTableRow
                  key={row._id}
                  row={row}
                  onViewRow={handleMoveToNextPage(row._id, 'details')}
                  onDeleteRow={handleDeleteRow(row._id)}
                  detailsOnClickHandler={onDetailsClickHandler(row._id)}
                  showPublishedDate={showPublishedDate}
                  showDetailsBtn={showDetailsBtn}
                  showPartnerFee={showPartnerFee}
                />
              ))}
            </>
          )}
        />

        <TablePaginationCustom
          count={offeringList.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Card>
    </>
  );
});

const applyFilter = (data: ProjectsOffering[], filters: TableFilters) => {
  let resultData = _.cloneDeep(data);
  const {
    name,
    investmentType,
    projectType,
    offerStatus,
  } = filters;

  if (name) {
    const nameStr = handleStringFilter(name);
    resultData = resultData?.filter((item) => {
      return item.scenario?.calculate.output['Project type']?.toLowerCase().includes(nameStr) ||
        handleStringFilter(item.scenario?.lead?.location.address).includes(nameStr) ||
        handleStringFilter(item.softCommitted).includes(nameStr) ||
        handleStringFilter(item.required).includes(nameStr) ||
        handleStringFilter(item.raised).includes(nameStr) ||
        handleStringFilter(item.investmentType).includes(nameStr) ||
        handleStringFilter(item.status).includes(nameStr);
    });
  }

  if (investmentType?.length > 0) {
    resultData = resultData?.filter(item => investmentType.includes(item.investmentType));
  }

  if (projectType?.length > 0) {
    resultData = resultData?.filter(item => projectType.includes(item.scenario?.calculate.output['Project type'] as ProjectType));
  }

  if (offerStatus !== 'All') {
    resultData = resultData?.filter((item) => item.status === offerStatus);
  }

  return resultData;
};
