import { demoMarketPlaces, demoScenario } from 'src/constants/demo-mock-data';

import { DebtInvestmentType, ProjectsOffering, ProjectsOfferingStatusType, ProjectType } from '../../../../types';
import { TableHeadType } from '../leads-page';
import { TableFilters } from './types';

export const _projectsOfferingStatusList: ProjectsOfferingStatusType[] = ['All', 'Ongoing Offerings', 'Capital Raised', 'Unlisted'];
export const _investmentList: DebtInvestmentType[] = ['Debt', 'Equity'];
export const _projectTypeList: ProjectType[] = [
  'New build apartments', 'New build houses', 'New build houses and apartments', 'Investment',
  'Conversion', 'Permitted development'];

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: 'name',
    label: 'Name / Type',
    width: 300,
  },
  {
    _id: 'softCommitted',
    label: 'Soft Committed',
    width: 170,
    align: 'right',
  },
  {
    _id: 'raised',
    label: 'Raised / Required',
    width: 170,
    align: 'right',
  },
  {
    _id: 'investmentType',
    label: 'Inv. Type',
    width: 170,
  },
  {
    _id: 'status',
    label: 'Status',
    width: 110,
  },
  {
    _id: 'controls',
    width: 50,
  },
];

export const MARKETPLACE_TABLE_HEAD: TableHeadType[] = [
  { ...TABLE_HEAD[0], label: 'Name / Published' },
  ...TABLE_HEAD.slice(1),
];

export const PARTNER_TABLE_HEAD = MARKETPLACE_TABLE_HEAD.slice(0, 3).concat({
  _id: 'partnerFee',
  label: 'Partner fee',
  width: 120,
  align: 'right',
}, TABLE_HEAD.slice(3));


export const defaultFilters: TableFilters = {
  name: '',
  offerStatus: 'All',
  investmentType: [],
  projectType: [],
};

export const _mockProjectsOfferingTableData: ProjectsOffering[] = [
  {
    _id: '1',
    imgSrc: '1 logo',
    published: '05 Jan 2024',
    softCommitted: 10_805_000,
    raised: 14_805_000,
    required: 25_490_058,
    partnerFee: 105_000,
    partnerFeePercent: 2.2,
    name: 'Ski Resort complex',
    projectType: 'New build apartments',
    investmentType: 'Debt',
    status: 'Ongoing Offerings',
    scenario: {
      ...demoScenario,
      lead: {
        ...demoScenario.lead!,
        location: {
          ...demoScenario.lead!.location!,
          address: 'Igelsta Strandentré',
        },
      },
    },
    offer: { marketplaces: demoMarketPlaces },
  },
  {
    _id: '2',
    imgSrc: '2 logo',
    published: '05 Jan 2024',
    softCommitted: 805_000,
    raised: 1_805_000,
    required: 5_671_240,
    partnerFee: 105_000,
    partnerFeePercent: 2.2,
    name: 'Ski Resort complex',
    projectType: 'New build apartments',
    investmentType: 'Equity',
    status: 'Ongoing Offerings',
    scenario: {
      ...demoScenario,
      lead: {
        ...demoScenario.lead!,
        location: {
          ...demoScenario.lead!.location!,
          address: 'Gökegårds Tun',
        },
      },
    },
    offer: { marketplaces: demoMarketPlaces },
  },
  {
    _id: '3',
    imgSrc: '3 logo',
    published: '05 Jan 2024',
    softCommitted: 105_000,
    raised: 10_900_000,
    required: 33_915_492,
    partnerFee: 105_000,
    partnerFeePercent: 2.2,
    name: 'Ski Resort complex',
    projectType: 'New build apartments',
    investmentType: 'Equity',
    status: 'Capital Raised',
    scenario: {
      ...demoScenario,
      lead: {
        ...demoScenario.lead!,
        location: {
          ...demoScenario.lead!.location!,
          address: 'Igelsta Loft',
        },
      },
    },
    offer: { marketplaces: demoMarketPlaces },
  },
  {
    _id: '4',
    imgSrc: '4 logo',
    published: '05 Jan 2024',
    softCommitted: 205_000,
    raised: 14_805_000,
    required: 22_521_316,
    partnerFee: 105_000,
    partnerFeePercent: 2.2,
    name: 'Ski Resort complex',
    projectType: 'New build apartments',
    investmentType: 'Equity',
    status: 'Unlisted',
    scenario: {
      ...demoScenario,
      lead: {
        ...demoScenario.lead!,
        location: {
          ...demoScenario.lead!.location!,
          address: 'Älvsjö Loft',
        },
      },
    },
    offer: { marketplaces: demoMarketPlaces },
  },
  {
    _id: '5',
    imgSrc: '5 logo',
    published: '05 Jan 2024',
    softCommitted: 5_805_000,
    raised: 10_396_000,
    required: 24_416_000,
    partnerFee: 105_000,
    partnerFeePercent: 2.2,
    name: 'Ski Resort complex',
    projectType: 'New build apartments',
    investmentType: 'Equity',
    status: 'Ongoing Offerings',
    scenario: {
      ...demoScenario,
      lead: {
        ...demoScenario.lead!,
        location: {
          ...demoScenario.lead!.location!,
          address: 'Gunhild Ägarlägenheter',
        },
      },
    },
    offer: { marketplaces: demoMarketPlaces },
  },
];
