import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { _mockProjectsOfferingTableData } from 'src/features/projects-offering-page';

import { MarketplaceProjectDetail } from '../../../../../../../../types';
import { OfferingActivity } from './offering-activity';
import { OfferingActivityList } from './offering-activity-list/offering-activity-list';
import { OfferingSummary } from './offering-summary';
import { OfferingTotal } from './offering-total';

const projects = _mockProjectsOfferingTableData[0].offer.marketplaces || [];

type OfferingProps = {
  detailData: MarketplaceProjectDetail;
  isPartnerOffering?: boolean;
}

export const Offering = (props: OfferingProps) => {
  const { detailData, isPartnerOffering } = props;

  return (
    <Stack gap={5}>
      <Stack direction='row' gap={3}>
        <Box sx={{ flex: 1, maxWidth: 220 }}>
          <OfferingSummary detailData={detailData} />
        </Box>
        <Box sx={{ flex: 1 }}>
          <OfferingActivity
            projects={projects}
            title={isPartnerOffering ? 'Funding Activity' : 'Activity'}
          />
        </Box>
        <Box sx={{ flex: 1, maxWidth: 320 }}>
          <OfferingTotal
            projects={projects}
            title={isPartnerOffering ? 'Funding Overview' : 'Overview'}
          />
        </Box>
      </Stack>
      <OfferingActivityList isPartnerOffering={isPartnerOffering} />
    </Stack>
  );
};
