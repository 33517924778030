import { Stack, Step, StepLabel, Stepper } from '@mui/material';

import { Connector, StepIconComponent } from './components';

interface StepperCustomProps {
  steps: StepType[];
  activeStep: number;
}

export type StepType = {
  label: string;
}

export const StepperCustom = (props: StepperCustomProps) => {
  const {
    steps,
    activeStep,
  } = props;

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<Connector/>}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel sx={{ whiteSpace: 'nowrap' }} StepIconComponent={StepIconComponent}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};
