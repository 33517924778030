import { _mockProjectsOfferingTableData } from 'src/features/projects-offering-page';

import { ProjectsOffering } from '../../../../types';
import { setIsLoading } from '../storage';
import { AppThunk } from '../store';
import { setProjectsOffering } from './index';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const loadProjectsOffering = (page: number, records: number): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const projectOffering: ProjectsOffering[] = _mockProjectsOfferingTableData;
    dispatch(setProjectsOffering(projectOffering));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const unlistProjectsOffering = (projectOfferId: string): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};
