import _ from 'lodash';

import {
  ActivityStatus,
  ChipColorsType,
  DefaultChipVariantsType,
  DefaultPartnerTransactionStatusColors,
  DefaultProjectsOfferingStatusColors,
  DefaultProjectTypeColors,
  DefaultSellStatusColors,
  DefaultStageColors,
  DefaultStatusColors,
  DeptNameType,
  LabelColor,
  SectionValues,
  SourceType,
  VisibilityListType,
} from '../../../types';
import { CheckboxOptionType } from '../components';

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};

export const INVESTOR_PORTAL = {
  MAX_WIDTH: 1152,
  HEADER_H: 64,
};

export const DEFAULT_CURRENCY = 'kr';
export const DEFAULT_AREA = 'm²';
export const UNSPECIFIED_VALUE = 'Unspecified';
export const EMPTY_VALUE = '-';

export const closeIcon = 'solar:close-circle-bold';
export const checkboxIcon = 'eva:checkmark-circle-2-fill';

export const debtTypeColor: Record<DeptNameType, LabelColor> = {
  All: 'success',
  Senior: 'primary',
  Junior: 'secondary',
  Bridge: 'success',
  Other: 'warning',
};

export const sourceColor: Record<SourceType, LabelColor> = {
  Manual: 'error',
  Scraping: 'success',
  'Owner Request': 'info',
};

export const stageColor: DefaultStageColors = {
  All: 'success',
  Lead: 'secondary',
  Approved: 'success',
  'TS In Progress': 'info',
  'In Progress': 'info',
  'TS Signed': 'success',
  'DFA In Progress': 'primary',
  'DFA Signed': 'success',
  'Done': 'success',
};

export const projectsOfferingColor: DefaultProjectsOfferingStatusColors = {
  All: 'success',
  Unlisted: 'error',
  'Ongoing Offerings': 'info',
  'Capital Raised': 'success',
};

export const projectTypeColor: DefaultProjectTypeColors = {
  'New build apartments': 'default',
  'New build houses': 'default',
  'Investment': 'default',
  'Conversion': 'default',
  'Permitted development': 'default',
  'New build houses and apartments': 'default',
};

export const statusColor: DefaultStatusColors = {
  All: 'success',
  New: 'default',
  Recalculate: 'warning',
  Approved: 'success',
  Researched: 'success',
  Done: 'success',
  Pending: 'secondary',
  'In Progress': 'info',
  'Waiting for Review': 'secondary',
  Rejected: 'error',
  Requested: 'info',
  Signed: 'success',
  Uploaded: 'success',
  'Term Sheet Requested': 'info',
  'Documents Added': 'success',
  'Documents Requested': 'secondary',
  'Agreement Requested': 'info',
};

export const chipVariants: DefaultChipVariantsType = {
  Manual: 'soft',
  Scraping: 'soft',
  New: 'outlined',
  Recalculate: 'outlined',
  'Owner Request': 'soft',
  All: 'outlined',
  Researched: 'outlined',
  Pending: 'outlined',
  Approved: 'outlined',
  'In Progress': 'outlined',
  'Waiting for Review': 'outlined',
  'No Partners': 'outlined',
  'Published': 'outlined',
  Rejected: 'outlined',
  'New build apartments': 'soft',
  'New build houses': 'soft',
  'Investment': 'soft',
  'Conversion': 'soft',
  'Permitted development': 'soft',
  'New build houses and apartments': 'soft',
  Lead: 'outlined',
  Requested: 'outlined',
  Signed: 'outlined',
  Uploaded: 'outlined',
  'Not Published': 'soft',
  Shared: 'outlined',
  'On marketplace': 'outlined',
  Finalised: 'outlined',
  'Ongoing Offerings': 'outlined',
  'Capital Raised': 'outlined',
  'Done': 'outlined',
  Unlisted: 'outlined',
  Debt: 'soft',
  Equity: 'soft',
  Preliminary: 'soft',
  Packaged: 'soft',
  'Sign Loan': 'soft',
  'Term Sheet': 'soft',
  'Term Sheet Requested': 'outlined',
  'TS In Progress': 'outlined',
  'TS Signed': 'outlined',
  'DFA In Progress': 'outlined',
  'DFA Signed': 'outlined',
  'Documents Added': 'outlined',
  'Documents Requested': 'outlined',
  'Agreement Requested': 'outlined',
  'Not Selling': 'outlined',
  'On Sale': 'outlined',
  Sold: 'outlined',
};

export const partnerTransactionStatusColor: DefaultPartnerTransactionStatusColors = {
  'Pending': 'success',
  'Sent': 'secondary',
};

export const marketplaceProjectActivityStatusList: ActivityStatus[] = ['Reserved', 'Commitment Signed', 'Invoice sent', 'Secured capital', 'Archive'];

export const monthsShortLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const sectionsList: SectionValues[] = ['Lead', 'Research', 'Tendering', 'Financing'];
export const sellList = ['Not Selling', 'Sold', 'On Sale'];

export const acceptedFilesType = '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png';

export const visibilityChipColor: Record<VisibilityListType, ChipColorsType> = {
  'Not published': 'default',
  Published: 'success',
};

export const sellStatusColor: DefaultSellStatusColors = {
  All: 'success',
  'Not Selling': 'default',
  'On Sale': 'primary',
  Sold: 'success',
};

export const distributionCheckboxesList: CheckboxOptionType[] = [
  {
    label: 'Research',
    value: 'research',
  },
  {
    label: 'Project Preparation',
    value: 'projectPreparation',
  },
  {
    label: 'Tendering',
    value: 'tendering',
  },
  {
    label: 'Debt finance',
    value: 'debtFinancing',
  },
  {
    label: 'Equity finance',
    value: 'equityFinancing',
  },
  {
    label: 'Sell',
    value: 'sell',
  },
];

export const slicedDistributionCheckboxesList: CheckboxOptionType[] = _.slice(distributionCheckboxesList, 2, 6);

export const distributionCheckboxesListForLead = (reducedMenu: boolean): CheckboxOptionType[] => distributionCheckboxesList.map(item => {
  return {
    ...item,
    disabled: reducedMenu && ['tendering', 'equityFinancing'].includes(item.value),
  };
});

export const distributionCheckboxesListForAsset = (reducedMenu: boolean): CheckboxOptionType[] => distributionCheckboxesList.map(item => {
  return {
    ...item,
    disabled: ['research', 'projectPreparation'].includes(item.value) || (reducedMenu && ['tendering', 'equityFinancing'].includes(item.value)),
  };
});
