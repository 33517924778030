import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { m } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { CustomPopover, varHover } from 'src/components';
import { useMockedUser, usePopover, useRouter } from 'src/hooks';
import { logoutThunk } from 'src/store/auth';

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    //TODO add link
    linkTo: '',
  },
  {
    label: 'Settings',
    //TPDO add link
    linkTo: '',
  },
];

export const AccountPopover = () => {
  const router = useRouter();
  const { user } = useMockedUser();
  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      dispatch(logoutThunk());
      popover.onClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap='tap'
        whileHover='hover'
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.photoURL}
          alt={user?.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.displayName.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{
        width: 200,
        p: 0,
      }}>
        <Box sx={{
          p: 2,
          pb: 1.5,
        }}>
          <Typography variant='subtitle2' noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }}/>

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }}/>

        <MenuItem
          onClick={handleLogout}
          sx={{
            m: 1,
            fontWeight: 'fontWeightBold',
            color: 'error.main',
          }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
};
