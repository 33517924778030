import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card, CardHeader, Container, Grid, Stack, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { omit } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessfulCaseIcon } from 'src/assets';
import { CrumbType, FormProvider, ProjectToolbar, RHFAutocomplete, RHFEditor, RHFTextField } from 'src/components';
import { InfoModal, PreviewBlock } from 'src/features/debt-request';
import { useBoolean, useResponsive, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { createLead, loadLeadDetails } from 'src/store/lead';
import { shareWithClients } from 'src/store/sell';
import { isEmailValid } from 'src/utils';
import * as Yup from 'yup';

import { SaleProjectType } from '../../../../../types';

export interface SaleProjectProps {
  crumbs: CrumbType[];
}

export const SaleProject = (props: SaleProjectProps) => {
  const { crumbs } = props;
  const router = useRouter();
  const infoModal = useBoolean();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);

  const project = detailsData.project;
  const lead = detailsData.lead;

  const SaleProjectSchema: Yup.ObjectSchema<SaleProjectType> = Yup.object().shape({
    clientEmails: Yup.array().min(1, 'Write at least one client email').required().test('partners-email', 'One of email is invalid', value => {
      return value.every(item => isEmailValid(item));
    }),
    projectPrice: Yup.number(),
    buyersFee: Yup.number().required('Buyer\'s price is required').moreThan(0, 'Buyer\'s price must be greater than 0'),
    emailNote: Yup.string(),
  });

  const defaultValues: SaleProjectType = useMemo(() => ({
    clientEmails: [],
    projectPrice: lead?.price || 0,
    buyersFee: lead?.buyerFeeAmount || 0,
    emailNote: '',
  }), [lead?.buyerFeeAmount, lead?.price]);


  const methods = useForm<SaleProjectType>({
    resolver: yupResolver(SaleProjectSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      dispatch(shareWithClients(data, () => {
        dispatch(createLead({
          lead: {
            ...omit(lead, 'status'),
            buyerFeeAmount: data?.buyersFee,
          },
          callback: () => {
            lead?._id && dispatch(loadLeadDetails(lead?._id));
          },
        }));
      }));
      infoModal.onTrue();
      reset(defaultValues);
    } catch (error) {
      console.error(error);
    }
  });

  const closeInfoModalClickHandler = () => {
    router.push('raise-monitoring');
  };

  const onPreviewClickHandler = () => {
    console.log('TODO preview');
  };

  // const selectAllHandler = () => {
  //   setValue('clientEmails', clients);
  // };

  // const deleteClient = (clientId?: string) => () => {
  //   const filteredClients = values.partnersIds.filter(item => item._id !== clientId);
  //   setValue('partnersIds', filteredClients);
  // };

  // const clientSelectHandler = (client: ClientType) => () => {
  //   const isClientAlreadySelected = values.clientEmails.find(item => item._id === client._id);
  //   if (isClientAlreadySelected) {
  //     const filteredClients = values.clientEmails.filter(item => item._id !== client._id);
  //     setValue('clientEmails', filteredClients);
  //     return;
  //   }
  //   setValue('clientEmails', [...values.clientEmails, client]);
  //   clearErrors('clientEmails');
  // };
  //
  // const addClientHandler = (client: ClientType) => {
  //   const updatedClient: ClientType = {
  //     ...client,
  //     registeredData: fDate(new Date()),
  //     _id: Math.random().toString(),
  //     type: 'Family Investment Office',
  //   };
  //   dispatch(setClients([...clients, updatedClient]));
  // };

  const renderSaleProject = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Sale Project
          </Typography>
          <Typography mb={3} variant='body2' sx={{ color: 'text.secondary' }}>
            Add clients who might be interested <br/>
            in a full buyout of this project. Also, <br/>
            edit the sale terms if necessary.
          </Typography>
          <PreviewBlock
            title={'Review your offer from the buyer\'s perspective'}
            onPreviewClickHandler={onPreviewClickHandler}
            width={212}
          />
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title='Sale Project'/>}
          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFAutocomplete
              name='clientEmails'
              label='* Clients'
              placeholder='Clients'
              multiple
              freeSolo
              options={[]}
              getOptionLabel={undefined}
              renderOption={undefined}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option}
                    label={option}
                    size='small'
                    variant='soft'
                  />
                ))
              }
            />
            <Stack
              gap={2}
              sx={{
                p: 3,
                borderRadius: 1.5,
                border: `solid 1px ${theme.palette.divider}`,
              }}
            >
              <Stack direction='row' gap={2}>
                <Typography
                  sx={{ minWidth: 210 }}
                  variant='body2'
                  color={theme.palette.text.secondary}
                >
                  Purchase price
                </Typography>
                <RHFTextField
                  disabled
                  type='number'
                  name='projectPrice'
                  InputProps={{ sx: { height: 40 } }}
                />
              </Stack>
              <Stack direction='row' gap={2}>
                <Typography
                  sx={{ minWidth: 210 }}
                  variant='body2'
                  color={theme.palette.text.secondary}
                >
                  Buyer's fee
                </Typography>
                <RHFTextField
                  disabled={!!lead?.buyerFeeAmount}
                  type='number'
                  name='buyersFee'
                  InputProps={{ sx: { height: 40 } }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderEmail = (
    <>
      {mdUp && (
        <Grid md={4}>
        </Grid>
      )}
      <Grid xs={12} md={8}>
        <Card>
          <CardContent>
            <Stack spacing={1.5}>
              <Typography variant='subtitle2'>Email</Typography>
              <RHFEditor simple name='emailNote'/>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
  const renderActions = (
    <LoadingButton
      sx={{ ml: 'auto' }}
      type='submit'
      variant='contained'
      size='large'
      loading={isSubmitting}
    >
      Share
    </LoadingButton>
  );


  return (
    <Container maxWidth={false} sx={{ px: 2 }}>
      <ProjectToolbar
        showSkeleton={false}
        showBadge={false}
        showBackIcon={false}
        crumbs={crumbs}
        title={project?.name}
      />
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid
          container
          sx={{
            mx: 0,
            px: 3,
          }}
          rowGap={3}
        >
          {renderSaleProject}
          {renderEmail}
          {renderActions}
        </Grid>
      </FormProvider>
      {/*<ClientsModal*/}
      {/*  clients={clients}*/}
      {/*  open={clientsModal.value}*/}
      {/*  onClose={clientsModal.onFalse}*/}
      {/*  selectedClients={values.partnersIds}*/}
      {/*  selectAllHandler={selectAllHandler}*/}
      {/*  clientSelectHandler={clientSelectHandler}*/}
      {/*  addClientHandler={addClientHandler}*/}
      {/*/>*/}
      <InfoModal
        open={infoModal.value}
        onClose={closeInfoModalClickHandler}
        title='Project Shared Successfully!'
        icon={<SuccessfulCaseIcon/>}
        btnTitle='Open Project'
        btnConfig={{ onClick: closeInfoModalClickHandler }}
      />
    </Container>
  );
};
