import { OmittedSellOfferType } from 'src/components';

import { RaiseMonitoringType, SaleProjectType, SellProjectBodyParams, SellResponse, SellStatusListType } from '../../../../types';
import { apiFetch } from '../api';
import { setDetailViewData, setIsLoading } from '../storage';
import { AppThunk } from '../store';
import { setSellItems } from './index';

export const loadSellProjects = (paramBody: SellProjectBodyParams): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const sellResponse: SellResponse = await apiFetch('sell/list', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: { 'Content-Type': 'application/json' },
    });

    dispatch(setSellItems(sellResponse));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setSellItems({ projects: [] }));
    dispatch(setIsLoading(false));
  }
};

export const updateSellStatus = (newStatus: SellStatusListType): AppThunk => async (dispatch, getState) => {
  const detailsData = getState().Storage.detailsData;
  try {
    if (detailsData.project?._id) {
      const response = await apiFetch(`sell/update-status/${detailsData.project._id}/${newStatus}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
      });
      if (response._id) {
        dispatch(setDetailViewData({
          ...detailsData,
          distribution: {
            ...detailsData.distribution,
            status: newStatus,
          },
        }));
      }
    }
  } catch (error) {
    console.error(error);
  }
};


export const shareWithClients = (formData: SaleProjectType, callBack?: VoidFunction): AppThunk => async (dispatch, getState) => {
  const detailsData = getState().Storage.detailsData;
  try {
    if (detailsData.project?._id) {
      const response = await apiFetch('sell/share', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          clientEmails: formData.clientEmails,
          price: formData.projectPrice,
          note: formData.emailNote,
          buyerFeeAmount: formData.buyersFee,
          projectId: detailsData.project._id,
        }),
      });
      const sharedData: RaiseMonitoringType[] = response.map((item: string, index: number) => {
        return {
          _id: item,
          projectId: detailsData.project?._id,
          price: formData.projectPrice,
          status: 'Shared',
          client: {
            _id: item,
            email: formData.clientEmails[index],
            createdAt: new Date(),
          },
        };
      });
      dispatch(setDetailViewData({
        ...detailsData,
        distribution: {
          ...detailsData.distribution,
          buyerFeeAmount: formData.buyersFee,
          price: formData.projectPrice,
          sharedCounter: (detailsData.distribution?.sharedCounter || 0) + formData.clientEmails.length,
          shared: [
            ...(detailsData.distribution?.shared || []),
            ...sharedData,
          ],
        },
      }));
      callBack?.();
    }
  } catch (error) {
    console.error(error);
  }
};

export const addSellOfferThunk = (offerDetails: OmittedSellOfferType, currentItem: RaiseMonitoringType): AppThunk => async (dispatch, getState) => {
  const detailsData = getState().Storage.detailsData;
  try {
    const response = await apiFetch('sell/offer', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: offerDetails.email,
        fullname: offerDetails.fullname,
        phone: offerDetails.phone,
        offerProjectPrice: offerDetails.offerProjectPrice,
        distributionId: currentItem._id,
      }),
    });
    response._id && dispatch(setDetailViewData({
      ...detailsData,
      distribution: {
        ...detailsData.distribution,
        shared: detailsData.distribution?.shared?.filter(item => item._id !== currentItem?._id),
        offers: [
          ...(detailsData.distribution?.offers || []),
          {
            ...currentItem,
            offers: {
              _id: response._id,
              ...offerDetails,
            },
          },
        ],
      },
    }));

  } catch (error) {
    console.error(error);
  }
};


export const deleteSellItemThunk = (itemToDelete: RaiseMonitoringType): AppThunk => async (dispatch, getState) => {
  const detailsData = getState().Storage.detailsData;
  try {
    const deleteAction = itemToDelete.offers?._id ? 'offer' : 'distribution';
    await apiFetch(`sell/delete/${itemToDelete._id}/${deleteAction}`, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch(setDetailViewData({
      ...detailsData,
      distribution: {
        ...detailsData.distribution,
        sharedCounter: (detailsData.distribution?.sharedCounter || 1) - 1,
        shared: itemToDelete.offers?._id
          ? detailsData.distribution?.shared
          : detailsData.distribution?.shared?.filter(item => item._id !== itemToDelete?._id),
        offers: itemToDelete.offers?._id
          ? detailsData.distribution?.offers?.filter(item => item._id !== itemToDelete?._id)
          : detailsData.distribution?.offers,
      },
    }));

  } catch (error) {
    console.error(error);
  }
};

