import { AppBar, IconButton, Stack, Toolbar, useTheme } from '@mui/material';
import { PartnerLogo } from 'src/components';
import { HEADER, NAV } from 'src/constants/constants';
import { useOffSetTop, usePortalFlags, useResponsive, useSettingsContext } from 'src/hooks';
import { bgBlur } from 'src/theme/css';

import { Logo } from '../logo';
import { SvgColor } from '../svg-color';
import { AccountPopover, LanguagePopover, NotificationsPopover, Searchbar, SettingsButton } from './components';

interface HeaderProps {
  onOpenNav: VoidFunction;
}

export const Header = (props: HeaderProps) => {
  const { onOpenNav } = props;

  const { isPartnerPortal } = usePortalFlags();
  const theme = useTheme();
  const settings = useSettingsContext();
  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';
  const lgUp = useResponsive('up', 'lg');
  const offset = useOffSetTop(HEADER.H_DESKTOP);
  const offsetTop = offset && !isNavHorizontal;

  const renderContent = (
    <>
      {lgUp && isNavHorizontal &&
          <>
            {isPartnerPortal
              ? <PartnerLogo sx={{ mr: 2.5 }}/>
              : <Logo sx={{ mr: 2.5 }}/>}
          </>
      }

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src='/assets/navbar/burger-menu-icon.svg'/>
        </IconButton>
      )}

      <Searchbar/>

      <Stack
        flexGrow={1}
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        spacing={{
          xs: 0.5,
          sm: 1,
        }}
      >
        <LanguagePopover/>

        <NotificationsPopover/>

        <SettingsButton/>

        <AccountPopover/>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({ color: theme.palette.background.default }),
        transition: theme.transitions.create(['height'], { duration: theme.transitions.duration.shorter }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && { height: HEADER.H_DESKTOP_OFFSET }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && { width: `calc(100% - ${NAV.W_MINI + 1}px)` }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};
