import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomSnackbarProps } from 'src/components';
import { LocationOptionType } from 'src/features/new-lead';

import {
  Asset,
  AssigneeType,
  ComparablesResponse,
  DetailViewData,
  EquityDistributionPartnerType,
  Lead,
  Note,
  ReportList,
  ReportSettingsType,
  Scenario,
  UpdateStatus,
} from '../../../../types';

export * from './actions';

export interface AppState {
  reportList?: ReportList;
  reportListStatus: UpdateStatus;
  scenario?: Scenario;
  notes?: Note[];
  comparables: ComparablesResponse;
  addresses: LocationOptionType[];
  isLoading: boolean;
  snackbar?: CustomSnackbarProps;
  detailsData: DetailViewData;
  previewDetailsData: DetailViewData;
  previewDistributionPartners?: EquityDistributionPartnerType;
  reportSettings: ReportSettingsType[];
}

export const defaultSnackbarProps: CustomSnackbarProps = {
  show: false,
  title: '',
  timeout: 2000,
  color: 'success',
  iconKey: 'success',
};

export const initialState: AppState = {
  reportListStatus: 'new',
  isLoading: false,
  addresses: [],
  reportSettings: [],
  comparables: {
    count: 0,
    properties: [],
  },
  snackbar: defaultSnackbarProps,
  detailsData: {},
  previewDetailsData: {},
};

const calculateSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setReportList(state, action: PayloadAction<ReportList>) {
      state.reportList = action.payload;
    },
    setPreviewDetailsData(state, action: PayloadAction<DetailViewData>) {
      state.previewDetailsData = action.payload;
    },
    setPreviewDistributionPartners(state, action: PayloadAction<EquityDistributionPartnerType | undefined>) {
      state.previewDistributionPartners = action.payload;
    },
    setReportSettings(state, action: PayloadAction<ReportSettingsType[]>) {
      state.reportSettings = action.payload;
    },
    addNewReportSettings(state, action: PayloadAction<ReportSettingsType>) {
      const isSettingExistIndex = state.reportSettings.findIndex(setting =>
        setting.leadId === action.payload.leadId
        && (action.payload.identifier ? setting.identifier === action.payload.identifier : true)
        && setting.reportName === action.payload.reportName);
      if (isSettingExistIndex !== -1) {
        state.reportSettings[isSettingExistIndex] = action.payload;
        return;
      }
      state.reportSettings.push(action.payload);
    },
    setDetailViewData(state, action: PayloadAction<DetailViewData>) {
      state.detailsData = action.payload;
    },
    setAssetsViewData(state, action: PayloadAction<Asset | undefined>) {
      state.detailsData.assets = action.payload;
    },
    updateAssetsViewData(state, action: PayloadAction<Asset>) {
      state.detailsData.assets = { ...state.detailsData.assets, ...action.payload };
    },
    updateLeadDetailViewData(state, action: PayloadAction<Partial<Lead>>) {
      if (state.detailsData?.lead) {
        state.detailsData.lead = { ...state.detailsData?.lead, ...action.payload };
      }
    },
    addDetailsViewDataAssignee(state, action: PayloadAction<AssigneeType>) {
      if (state.detailsData) {
        state.detailsData.users = [...(state.detailsData.users || []), action.payload];
      }
    },
    removeDetailsViewDataAssignee(state, action: PayloadAction<AssigneeType | undefined>) {
      if (state.detailsData) {
        state.detailsData.users = state.detailsData.users?.filter(assignee => assignee._id !== action.payload?._id);
      }
    },
    setReportListStatus(state, action: PayloadAction<UpdateStatus>) {
      state.reportListStatus = action.payload;
    },
    setScenario(state, action: PayloadAction<Scenario>) {
      state.scenario = action.payload;
    },
    resetScenario(state) {
      state.scenario = undefined;
    },
    resetDetailsViewData(state) {
      state.detailsData = {};
    },
    setComparables(state, action: PayloadAction<ComparablesResponse>) {
      state.comparables = action.payload;
    },
    setAddresses(state, action: PayloadAction<LocationOptionType[]>) {
      state.addresses = action.payload;
    },
    setNotes(state, action: PayloadAction<Note[]>) {
      state.notes = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateSnackbarConfig(state, action: PayloadAction<CustomSnackbarProps>) {
      state.snackbar = {
        ...state.snackbar,
        show: true,
        ...action.payload,
      };
    },
  },
});

export const {
  reducer: StorageReducer,
  actions: {
    setReportList,
    setReportListStatus,
    setReportSettings,
    addNewReportSettings,
    setScenario,
    setNotes,
    setIsLoading,
    setComparables,
    setAddresses,
    resetScenario,
    updateSnackbarConfig,
    setDetailViewData,
    setPreviewDetailsData,
    setPreviewDistributionPartners,
    removeDetailsViewDataAssignee,
    addDetailsViewDataAssignee,
    updateLeadDetailViewData,
    setAssetsViewData,
    updateAssetsViewData,
    resetDetailsViewData,
  },
} = calculateSlice;
