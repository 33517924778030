import Stack from '@mui/material/Stack';
import { imgSrc } from 'src/features/marketplace-details/components/project-details/mock-data';

import { OverviewInfo } from './components/overview-info/overview-info';
import { OverviewPhotos } from './components/overview-photos';

const _photos = new Array(5).fill(imgSrc);

type OverviewProps =  {
  isPartner?: boolean;
}

export const Overview = (props: OverviewProps) => {
  const { isPartner } = props;

  return (
    <Stack gap={5}>
      <OverviewPhotos photos={_photos} />
      <OverviewInfo isPartner={isPartner} />
    </Stack>
  );
};
