import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { SendIcon } from 'src/assets';
import { Iconify, StyledTableRow } from 'src/components';
import { DEFAULT_CURRENCY, EMPTY_VALUE } from 'src/constants/constants';
import { marketplaceProjectActivityStatusColor } from 'src/features/marketplace-details/components/project-details/mock-data';
import { useBoolean } from 'src/hooks';
import { fNumber, fPercent } from 'src/utils';

import { MarketplaceProjectActivity } from '../../../../../../../../../types';
import { ActivityDrawer } from './activity-drawer';

type OfferingActivityTableRowProps = {
  row: MarketplaceProjectActivity;
}

export const OfferingActivityTableRow = (props: OfferingActivityTableRowProps) => {
  const { row } = props;
  const theme = useTheme();
  const drawer = useBoolean();

  const lastUpdateStr = row.lastUpdate;

  const { status } = row;
  const isReserved = status === 'Reserved';
  const isCommitmentSigned = status === 'Commitment Signed';

  const _isMarketplaceExternal = true;

  return (
    <>
      <StyledTableRow hover>
        <TableCell>
          <Stack direction='row' gap={1} alignItems='center'>
            <Typography variant='body2' color='text.primary'>
              {row.investorName}
            </Typography>
            {row.investorApproved &&
            <Iconify
              color={theme.palette.success.main} icon='eva:checkmark-circle-2-fill'
            />
            }
          </Stack>
          <Typography variant='caption' color={theme.palette.text.secondary}>
            {row.investorEmail}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant='body2'
            color={isReserved
              ? theme.palette.text.secondary
              : theme.palette.text.primary}
          >
            {fNumber(row.reserved)} {DEFAULT_CURRENCY}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body2' color='text.primary'>
            {row.secured ? `${fNumber(row.secured)} ${DEFAULT_CURRENCY}` : EMPTY_VALUE}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body2' color={isReserved ? theme.palette.text.secondary : theme.palette.text.primary}>
            {fNumber(row.commission)} {DEFAULT_CURRENCY}
          </Typography>
          <Typography variant='caption' color={theme.palette.text.secondary}>
            {fPercent(row.commissionPercent)}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body2' color='text.primary'>
            {lastUpdateStr}
          </Typography>
          <Typography variant='caption' color={theme.palette.text.secondary}>
            {row.lastUpdateName}
          </Typography>
        </TableCell>

        <TableCell>
          <Chip
            size='small'
            variant='outlined'
            color={marketplaceProjectActivityStatusColor[status] ?? 'default'}
            label={status}
          />
        </TableCell>

        <TableCell>
          {_isMarketplaceExternal && <>
            {isReserved &&
              <Button onClick={drawer.onTrue} variant='outlined' size='small'>Confirm</Button>
            }
            {isCommitmentSigned &&
              <Button onClick={drawer.onTrue} startIcon={<SendIcon />} variant='outlined' size='small'>
                Invoice
              </Button>
            }
          </>}
        </TableCell>
      </StyledTableRow>

      <ActivityDrawer drawer={drawer} status={status} />
    </>
  );
};
