import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { CardBarFiltersChart, ChartWithFiltersType, SeriesWithFiltersType } from 'src/components';
import { monthsShortLabels } from 'src/constants/constants';

import { MarketplaceOffer } from '../../../../../../../../types';

type OfferingActivityProps = {
  projects: MarketplaceOffer[];
  title?: string;
}

export const OfferingActivity = (props: OfferingActivityProps) => {
  const theme = useTheme();
  const { projects, title } = props;

  const additionalSeriesValues = useMemo(() => {
    const names = projects.map(item => item.name);
    names.unshift('All Projects');
    return names;
  }, [projects]);

  const generatedMockDataForChart: ChartWithFiltersType = useMemo(() => {
    const series: SeriesWithFiltersType[] = [
      {
        seriesFilter: 'Week',
        additionalFilter: 'All Projects',
        data: [
          {
            name: 'Soft Committed',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 200 * index),
          },
          {
            name: 'Capital Secured',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 15 * index),
          },
        ],
      },
      {
        seriesFilter: 'Month',
        additionalFilter: 'All Projects',
        data: [
          {
            name: 'Soft Committed',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 200 * index * 4),
          },
          {
            name: 'Capital Secured',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 15 * index * 4),
          },
        ],
      },
      {
        seriesFilter: 'Year',
        additionalFilter: 'All Projects',
        data: [
          {
            name: 'Soft Committed',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 200 * index * 48),
          },
          {
            name: 'Capital Secured',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 15 * index * 48),
          },
        ],
      },
    ];

    return {
      categories: monthsShortLabels,
      colors: [theme.palette.primary.lighter, theme.palette.primary.main],
      series: series,
    };
  }, [theme]);

  return (
    <CardBarFiltersChart
      cardProps={{ sx: { flex: 1 } }}
      title={title || 'Activity'}
      seriesValues={['Week', 'Month', 'Year']}
      additionalSeriesValues={additionalSeriesValues}
      chart={generatedMockDataForChart}
    />
  );
};
