import { useMemo } from 'react';

import { Scenario, UnitsArray } from '../../../../../types';

export const useScenarioCalculation = (scenario?: Scenario) => {
  const scenarioOutput = scenario?.calculate?.output;
  const scenarioInput = scenario?.calculate?.input;
  let timelineTotalCost = 0;
  let minTicketEquity = 0;
  let totalLoans = 0;
  const totalEquity = [0, 0];
  const buildCostPsqft = +Number(scenarioOutput?.['Build cost psqft'] || 0).toFixed(0);
  const costPSQM = (buildCostPsqft / 0.75).toFixed(0);

  scenarioOutput?.['Finance costs breakdown']?.forEach(finance => {
    const financeAmount = finance.amount || 0;
    const financeLtc = finance.ltc || 0;
    const financeLtgdv = finance.ltgdv || 0;
    timelineTotalCost += financeAmount;
    if (finance.type !== 'equity' && financeAmount > minTicketEquity) {
      minTicketEquity = financeAmount;
    }
    if (finance.type?.includes('debt')) {
      totalLoans += financeAmount;
    }
    if (finance.type?.includes('equity')) {
      totalEquity[0] += financeLtc;
      totalEquity[1] += financeLtgdv;
    }
  });

  const totalEquityPercentage = useMemo(() => (scenarioInput?.['funding_array'] || [])
    .reduce((acc, cur) => acc + (cur?.section === 'Equity' ? (cur?.percentage || 0) : 0), 0), [scenarioInput]);

  const totalExpectedReturn = useMemo(() => ((scenarioOutput?.['Net profit'] || 0) * totalEquityPercentage / 100), [scenarioOutput, totalEquityPercentage]);
  const possibleUpside = useMemo(() => (scenario?.sensitivityAnalysis?.[3]?.[5] / (scenarioOutput?.['Net profit'] || 1) * 100), [scenarioOutput, scenario?.sensitivityAnalysis]);

  const preferredReturn = scenarioOutput?.ROI || 0;

  const saleUnitPrice = useMemo(() => (scenarioInput?.units_array || [])
    .filter((item) => item.property_type !== 'BRF Loan (Sweden only)')
    .reduce?.((acc, cur) => acc + (cur.price_per_unit || 0), 0), [scenarioInput?.units_array]);
  const otherRevenue = useMemo(() => ((scenarioOutput?.GDV || 0) - saleUnitPrice), [scenarioOutput?.GDV, saleUnitPrice]);

  return {
    totalEquityPercentage,
    buildCostPsqft,
    timelineTotalCost,
    costPSQM,
    totalLoans,
    totalEquity,
    saleUnitPrice,
    otherRevenue,
    totalExpectedReturn,
    possibleUpside,
    minTicketEquity,
    preferredReturn,
  };
};

export const calculateUnitTypeQuantity = (unitsArray: UnitsArray[] = []) => {
  const filteredUnitType = unitsArray.filter(unit => !unit.property_type?.includes('BRF Loan'));
  const result: { label: string; value: number; }[] = [];
  filteredUnitType?.forEach((unit: UnitsArray) => {
    const existingEntry = result.find(entry => entry.label === `${unit.bedroom_no} room ${unit.property_type}`);
    if (existingEntry) {
      existingEntry.value += 1;
    } else {
      result.push({
        label: `${unit.bedroom_no} room ${unit.property_type}`,
        value: 1,
      });
    }
  });
  return result;
};
