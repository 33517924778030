import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import { memo, MouseEvent } from 'react';
import { ConfirmDialog, ListItemCustom, MoreOptions, OptionType, StyledTableRow } from 'src/components';
import { useBoolean, usePopover } from 'src/hooks';
import { fNumber, isCLickOnRow } from 'src/utils';

import { ReadyForInvestors } from '../../../../../types';

interface PreparationTableRowProps {
  row: ReadyForInvestors;
  onViewRow: VoidFunction;
  onDeleteRow: VoidFunction;
}


export const ReadyForInvestorsRow = memo((props: PreparationTableRowProps) => {
  const {
    row,
    onViewRow,
    onDeleteRow,
  } = props;

  const {
    project,
    status,
    equityDistributionPartnersCount,
    marketPublishesCount,
    phase,
  } = row;

  const statusString = status?.toString();
  const required = project?.scenario?.calculate?.output?.['Total project cost'];

  const theme = useTheme();
  const confirm = useBoolean();
  const popover = usePopover();

  const preparationMoreOption: OptionType[] = [
    {
      label: 'View',
      icon: 'view',
      closePopover: popover.onClose,
      itemOnClickHandler: onViewRow,
    },
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onViewRow();
  };

  return (
    <>
      <StyledTableRow hover onClick={handleRowOnClick}>
        <TableCell
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ListItemCustom
            showAvatar
            avatarSx={{
              borderRadius: 1.5,
              backgroundColor: '#eeeff1',
            }}
            listItemTextProps={{
              primaryTypographyProps: {
                whiteSpace: 'nowrap',
                variant: 'body2',
              },
            }}
            avatarSrc=''
            customAvatarIcon={<PhotoSizeSelectActualIcon sx={{ fill: '#c0c7d1' }}/>}
            primaryText={project?.name}
            secondaryText={project?.scenario?.calculate?.input?.type_of_project}
            secondaryTextTypographySx={{
              color: theme.palette.text.secondary,
              typography: 'caption',
            }}
          />
        </TableCell>
        <TableCell>
          <Chip
            size='small'
            variant='soft'
            color='default'
            label={phase}
          />
        </TableCell>
        <TableCell>
          <Stack direction='row' gap={0.5} flexWrap='wrap'>
            {statusString.includes('No Shared') &&
                <Chip
                  disabled
                  size='small'
                  variant='outlined'
                  color='default'
                  label='No Shared'
                />
            }
            {statusString.includes('Published') &&
                <Chip
                  size='small'
                  variant='outlined'
                  color='info'
                  label={`Published ${marketPublishesCount || 0}`}
                />
            }
            {(statusString.includes('Shared') && !statusString.includes('No')) &&
                <Chip
                  size='small'
                  variant='outlined'
                  color='secondary'
                  label={`Shared ${equityDistributionPartnersCount || 0}`}
                />
            }
            {statusString.includes('Raised') &&
                <Chip
                  size='small'
                  variant='outlined'
                  color='success'
                  label='Raised'
                />
            }
          </Stack>
        </TableCell>

        <TableCell align='right'>
          <ListItemText
            primaryTypographyProps={{
              typography: 'body2',
              ml: 'auto',
            }}
            secondaryTypographyProps={{
              component: 'span',
              typography: 'caption',
              ml: 'auto',
              color: 'text.disabled',
            }}
            primary={fNumber(required)}
            secondary='TODO'
          />
        </TableCell>
        <TableCell align='right'
          sx={{
            px: 1,
            whiteSpace: 'nowrap',
          }}
        >
          <Button
            sx={{ whiteSpace: 'nowrap' }}
            variant='outlined'
            size='small'
            onClick={onViewRow}
          >
            Review
          </Button>
          <MoreOptions
            options={preparationMoreOption}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        </TableCell>
      </StyledTableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Are you sure want to delete?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
});
