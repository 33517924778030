import Button from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TowerCraneIcon } from 'src/assets/svg';
import { CrumbType, DetailView, generateSummaryField } from 'src/components';
import { paths } from 'src/config';
import { greenGradient } from 'src/constants/colors';
import { statusColor } from 'src/constants/constants';
import { useRouter } from 'src/hooks';
import { RootState } from 'src/store';

export const TenderingDetails = () => {
  const {
    id,
    tab,
  } = useParams<{ id: string, tab: string }>();

  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const lead = detailsData?.lead;
  const scenario = detailsData?.project?.scenario;
  const scenarioInput = scenario?.calculate?.input;
  const isLoading = useSelector((state: RootState) => state.Storage.isLoading);

  const isRequest = tab === 'Request';
  const router = useRouter();
  const theme = useTheme();

  const crumbs = useMemo<CrumbType[]>(() => {
    const arr = [
      { title: 'Packaging' },
      { title: 'Tendering' },
      {
        title: lead?.location.address,
        color: isRequest ? undefined : theme.palette.text.disabled,
      },
    ];

    if (isRequest) {
      return [
        ...arr,
        {
          title: 'Preliminary Quotation Request',
          color: theme.palette.text.disabled,
        },
      ];
    }

    return arr;
  }, [lead?.location.address, isRequest, theme.palette.text.disabled]);

  const onStartClick = useCallback(() => {
    const path = paths.tendering.details(id, 'tendering-request');
    router.push(path);
  }, [id, router]);

  const summaryItems = useMemo(() => {
    return generateSummaryField({
      location: lead?.location.address,
      projectType: scenarioInput?.type_of_project,
      size: scenarioInput?.total_floor_area,
      projectManager: detailsData?.users?.find(user => user.designation === 'Project manager')?.name,
    });
  }, [detailsData?.users, lead?.location.address, scenarioInput?.total_floor_area, scenarioInput?.type_of_project]);

  return (
    <DetailView
      showToolbarBadge
      showOverviewCloud
      customSummaryItemsOnOverview={summaryItems}
      cloudProps={{
        showCloud: true,
        cardSx: { background: greenGradient },
        avatar: <TowerCraneIcon color={theme.palette.success.main}/>,
        btnTitle: 'Start',
        title: 'Preliminary Quotation Request',
        subtitle: 'Click Start to select suppliers for request Preliminary Quotation',
        titleColor: theme.palette.success.darker,
        subTitleColor: theme.palette.success.darker,
        onBtnClickHandler: onStartClick,
      }}
      componentsViews='default'
      crumbs={crumbs}
      showData={Boolean(scenario && !isLoading)}
      toolbarTitle={lead?.location.address}
      toolbarBadgeVariant='outlined'
      toolbarBadgeTitle='Pending'
      toolbarBadgeColor={statusColor['Pending']}
      toolbarChildren={
        <Button onClick={onStartClick} variant='outlined' size='medium'>
          Start Tendering
        </Button>
      }
      // todo: change note section
      noteSection='Research'
      overviewProps={{ activeStep: 2 }}
      scenario={scenario}
    />
  );
};
