import { Box, Button, IconButton, SelectChangeEvent, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CrossIcon } from 'src/assets/svg';
import { CustomSelect, DrawerCustom, InlineBadge, ListItemCustom, StepperCustom, StepType } from 'src/components';
import { useBoolean } from 'src/hooks';
import { RootState } from 'src/store';
import { fDate } from 'src/utils';

import { BacklogSectionsType } from '../../../../../types';
import { _mockBacklogItems, _mockProjects, _mockSections, steps as mockSteps } from '../mock-data';


export type BackLogItemType = {
  _id?: string;
  iconSrc?: string;
  name?: string;
  isNew?: boolean;
  lastAction?: string;
  actionDate?: string;
  section?: BacklogSectionsType;
  project?: string;
}

export interface StepperBacklogProps {
  activeStep: number;
  steps?: StepType[];
}

export const StepperBacklog = (props: StepperBacklogProps) => {
  const {
    activeStep,
    steps = mockSteps,
  } = props;

  const [backlogItems, setBacklogItems] = useState<BackLogItemType[]>([]);
  const [projectFilter, setProjectFilter] = useState<string>('All projects');
  const [sectionFilter, setSectionFilter] = useState<string>('All sections');
  const disableBacklog = useSelector((state: RootState) => state.App.config?.disableBacklog);
  const backlogDrawer = useBoolean();


  useEffect(() => {
    if (backlogDrawer.value) {
      const sortedBacklogItems = _.orderBy(_mockBacklogItems, ['isNew'], ['desc']);
      setBacklogItems(sortedBacklogItems);

      return () => {
        const updatedBacklogItems = _mockBacklogItems.map(backlogItem => ({
          ...backlogItem,
          isNew: false,
        }));
        setBacklogItems(updatedBacklogItems);
      };
    }
  }, [backlogDrawer.value]);

  const handleProjectFiler = useCallback((event: SelectChangeEvent<string[]>) => {
    const newProjectFilter = event.target.value;
    setProjectFilter(newProjectFilter as string);
  }, []);

  const handleSectionFiler = useCallback((event: SelectChangeEvent<string[]>) => {
    const newSectionFilter = event.target.value;
    setSectionFilter(newSectionFilter as string);
  }, []);

  const filteredBacklogItems = backlogItems.filter(item =>
    (projectFilter === 'All projects' || item.project === projectFilter) &&
    (sectionFilter === 'All sections' || item.section === sectionFilter),
  );

  return (
    <>
      <Stack
        direction='row'
        sx={{
          padding: 3,
          typography: 'body2',
        }}
        alignItems='center'
      >
        <StepperCustom
          steps={steps}
          activeStep={activeStep}
        />
        <Button
          disabled={disableBacklog}
          size='large'
          variant='outlined'
          onClick={backlogDrawer.onTrue}
        >
          Backlog
          <InlineBadge
            value={22}
            textColor={disableBacklog ? '#A0A0A0' : undefined}
            background={disableBacklog ? '#E0E0E0' : undefined}
          />
        </Button>
      </Stack>
      <DrawerCustom
        open={backlogDrawer.value}
        onCloseDrawerHandler={backlogDrawer.onFalse}
        headChildren={
          <>
            <Typography variant='h6'>Backlog</Typography>
            <IconButton onClick={backlogDrawer.onFalse}>
              <CrossIcon/>
            </IconButton>
          </>
        }
        bodyChildren={
          <Stack gap={2.5}>
            <Stack gap={2} direction='row' px={3}>
              <CustomSelect
                controlSx={{ width: 'calc(50% - 8px)' }}
                value={[projectFilter]}
                handleSelectOnChange={handleProjectFiler}
                options={_mockProjects}
              />
              <CustomSelect
                controlSx={{ width: 'calc(50% - 8px)' }}
                value={[sectionFilter]}
                handleSelectOnChange={handleSectionFiler}
                options={_mockSections}
              />
            </Stack>
            <Stack>
              {filteredBacklogItems.map((backlogItem) => {
                return (
                  <ListItemCustom
                    showAvatar
                    key={backlogItem._id}
                    listItemSx={{
                      borderBottom: (theme) => `1px dashed ${theme.palette.divider}`,
                      px: 3,
                    }}
                    avatarSrc={backlogItem.iconSrc}
                    avatarAlt={backlogItem.name}
                    primaryText={
                      <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack direction='row' gap={0.5}>
                          <Typography variant='subtitle2'>
                            {backlogItem.name}
                          </Typography>
                          <Typography variant='body2' color='text.primary'>
                            {backlogItem.lastAction}
                          </Typography>
                        </Stack>
                        {backlogItem.isNew &&
                          <Box
                            component='span'
                            sx={{
                              width: 10,
                              height: 10,
                              borderRadius: '50%',
                              bgcolor: (theme) => theme.palette.error.main,
                            }}
                          />}
                      </Stack>
                    }
                    secondaryChildren={
                      <Stack direction='row' gap={0.5} alignItems='center'>
                        <Typography
                          variant='body2'
                          sx={{
                            component: 'span',
                            color: 'text.disabled',
                            typography: 'caption',
                          }}
                        >
                          {fDate(backlogItem.actionDate)}
                        </Typography>
                        <Box
                          component='span'
                          sx={{
                            width: 2,
                            height: 2,
                            borderRadius: '50%',
                            bgcolor: 'text.disabled',
                          }}
                        />
                        <Typography
                          variant='body2'
                          sx={{
                            component: 'span',
                            color: 'text.disabled',
                            typography: 'caption',
                          }}
                        >
                          {backlogItem.section}
                        </Typography>
                      </Stack>
                    }
                  />
                );
              })}
            </Stack>
          </Stack>
        }
        bodyChildrenSx={{
          pt: 0,
          px: 0,
        }}
      />
    </>
  );
};
