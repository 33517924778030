import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { ThunkAction } from 'redux-thunk';

import { AppReducer } from './app';
import { AuthReducer } from './auth';
import { FinanceReducer } from './finance';
import { FinancialReducer } from './financial';
import { LeadReducer } from './lead';
import { MarketplaceProjectsOfferingReducer } from './marketplace-projects-offering';
import { PartnerProjectsOfferingReducer } from './partner-projects-offering';
import { PartnersReducer } from './partners';
import { ProjectPreparationReducer } from './project-preparation';
import { ProjectsOfferingReducer } from './projects-offering';
import { ReadyForInvestorsReducer } from './ready-for-investors';
import { ResearchReducer } from './research';
import { SellReducer } from './sell';
import { StorageReducer } from './storage';
import { TenderingReducer } from './tendering';

const history = createBrowserHistory();

export const rootReducer = combineReducers({
  App: AppReducer,
  Auth: AuthReducer,
  Lead: LeadReducer,
  Partners: PartnersReducer,
  Research: ResearchReducer,
  Storage: StorageReducer,
  Finance: FinanceReducer,
  Financial: FinancialReducer,
  ReadyForInvestors: ReadyForInvestorsReducer,
  ProjectsOffering: ProjectsOfferingReducer,
  MarketplaceProjectsOffering: MarketplaceProjectsOfferingReducer,
  PartnerProjectsOffering: PartnerProjectsOfferingReducer,
  Tendering: TenderingReducer,
  ProjectPreparation: ProjectPreparationReducer,
  Sell: SellReducer,
});
export type RootState = ReturnType<typeof rootReducer>

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

const store = configureStore({ reducer: rootReducer });

export { store, history };
