import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TableCustom } from 'src/components';
import { paths } from 'src/config';
import { MARKETPLACE_PROJECT_REPORTS_TABLE_HEAD } from 'src/features/marketplace-details/components/project-details/mock-data';
import { useRouter, useTable } from 'src/hooks';
import { RootState } from 'src/store';
import { openInNewTab } from 'src/utils';

import { MarketplaceProjectReport } from '../../../../../../../../types';
import { getPathForReport, reportCustomSort } from './helpers';
import { ReportTableRow } from './reports-table-row';

export const Reports = () => {
  const { id } = useParams<{ id: string }>();
  const table = useTable({ defaultOrderBy: 'name' });
  const router = useRouter();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const detailsReports = useMemo(() => detailsData.reports || [], [detailsData.reports]);

  const reports: MarketplaceProjectReport[] = useMemo(() => {
    return [
      {
        _id: 'generalReport',
        name: 'General report',
        createdAt: '20 May 2022',
        updatedAt: null,
        isOpenInNewTab: true,
        url: paths.reports.calculatorReport(id),
        svgColor: '#004b50',
        type: 'calculator',
        visibility: 'Published',
      },
      ...detailsReports.map(report => getPathForReport(id, report)),
    ];
  }, [detailsReports, id]);

  const dataFiltered: MarketplaceProjectReport[] = reportCustomSort(reports, table);
  const noDataFound = reports?.length === 0;

  const onOpenClick = (row: MarketplaceProjectReport) => () => {
    const {
      isOpenInNewTab,
      url,
    } = row;
    isOpenInNewTab
      ? openInNewTab(url)
      : router.push(url);
  };

  return (
    <TableCustom
      separateRows
      scrollbar={false}
      headLabels={MARKETPLACE_PROJECT_REPORTS_TABLE_HEAD}
      table={table}
      rowCount={reports?.length}
      noDataFound={noDataFound}
      bodyCells={(
        <>
          {dataFiltered?.map((row) => (
            <ReportTableRow
              key={row._id}
              onOpenClick={onOpenClick(row)}
              row={row}
            />
          ))}
        </>
      )}
    />
  );
};
