import { TabType } from 'src/components';

import {
  LabelColor,
  MarketplaceProjectActivity,
  MarketplaceProjectActivityStatusType,
  MarketplaceProjectDetail,
  MarketplaceProjectFile,
  MarketplaceProjectReport,
} from '../../../../../../types';

const details: MarketplaceProjectDetail[] = [
  {
    _id: '1',
    name: 'Ski Resort Сomplex 1',
    fee: null,
    views: 456,
    marketplaceId: '1',
    marketplaceName: 'Navian',
    marketplaceUrl: 'https://navian.io',
    investmentType: 'Equity',
    published: '05 Jan 2024',
    lastEdit: '05 Jan 2024',
    refLink: 'https://navian.io?ref=todo',
    status: 'Ongoing Offerings',
  },
  {
    _id: '2',
    name: 'Ski Resort Сomplex 2',
    fee: 0.02,
    views: 31,
    marketplaceId: '2',
    marketplaceName: 'Pareto',
    marketplaceUrl: 'https://pareto.com',
    investmentType: 'Debt',
    published: '05 Jan 2024',
    lastEdit: '05 Jan 2024',
    refLink: 'https://pareto.com?ref=todo',
    status: 'Ongoing Offerings',
  },
  {
    _id: '3',
    name: 'Ski Resort Сomplex 3',
    fee: 0.02,
    views: 31,
    marketplaceId: '2',
    marketplaceName: 'Pareto',
    marketplaceUrl: 'https://pareto.com',
    investmentType: 'Debt',
    published: '05 Jan 2024',
    lastEdit: '05 Jan 2024',
    refLink: 'https://pareto.com?ref=todo',
    status: 'Ongoing Offerings',
  },
];

export const _mockMarketplaceProjectDetails = (id: string): MarketplaceProjectDetail => {
  return details.find(d => d._id === id)!;
};

export const TABS: TabType[] = [
  {
    value: 'Offering',
    label: 'Offering',
  },
  {
    value: 'Overview',
    label: 'Overview',
  },
  {
    value: 'Reports',
    label: 'Reports',
  },
  {
    value: 'Team',
    label: 'Team',
  },
  {
    value: 'Files',
    label: 'Files',
  },
  {
    value: 'Assets',
    label: 'Assets',
  },
];

export const _mockMarketplaceProjectActivity: MarketplaceProjectActivity[] = [
  {
    _id: '1',
    investorApproved: false,
    investorName: 'Kenzi Lawson',
    investorEmail: 'kenzi.lawson@example.com',
    reserved: 500_000,
    secured: null,
    commission: 30_000,
    commissionPercent: 2,
    lastUpdate: '12 Jun 2023',
    lastUpdateName: 'Robert Fox',
    status: 'Reserved',
  },
  {
    _id: '2',
    investorApproved: true,
    investorName: 'Alma Lawson',
    investorEmail: 'alma.lawson@example.com',
    reserved: 500_000,
    secured: null,
    commission: 30_000,
    commissionPercent: 2,
    lastUpdate: '12 Jun 2023',
    lastUpdateName: 'Robert Fox',
    status: 'Commitment Signed',
  },
  {
    _id: '3',
    investorApproved: true,
    investorName: 'Michelle Rivera',
    investorEmail: 'michelle.rivera@example.com',
    reserved: 500_000,
    secured: null,
    commission: 30_000,
    commissionPercent: 2,
    lastUpdate: '12 Jun 2023',
    lastUpdateName: 'Robert Fox',
    status: 'Invoice sent',
  },
  {
    _id: '4',
    investorApproved: true,
    investorName: 'Bill Sanders',
    investorEmail: 'bill.sanders@example.com',
    reserved: 500_000,
    secured: 500_000,
    commission: 30_000,
    commissionPercent: 2,
    lastUpdate: '12 Jun 2023',
    lastUpdateName: 'Robert Fox',
    status: 'Secured capital',
  },
  {
    _id: '5',
    investorApproved: false,
    investorName: 'Debra Holt',
    investorEmail: 'debra.holt@example.com',
    reserved: 500_000,
    secured: null,
    commission: 30_000,
    commissionPercent: 2,
    lastUpdate: '12 Jun 2023',
    lastUpdateName: 'Robert Fox',
    status: 'Reserved',
  },
];

export const TABLE_HEAD = [
  {
    _id: 'investor',
    label: 'Investor',
  },
  {
    _id: 'reserved',
    label: 'Reserved',
    width: 120,
  },
  {
    _id: 'secured',
    label: 'Secured',
    width: 120,
  },
  {
    _id: 'commission',
    label: 'Commission',
    width: 120,
  },
  {
    _id: 'lastUpdate',
    label: 'Last Update',
    width: 160,
  },
  {
    _id: 'status',
    label: 'Status',
  },
  {
    _id: 'action',
    width: 96,
  },
];


export type MarketplaceProjectActivityStatusColors = {
  [key in MarketplaceProjectActivityStatusType]: LabelColor;
};

export const marketplaceProjectActivityStatusColor: MarketplaceProjectActivityStatusColors = {
  'Archive': 'secondary',
  'Reserved': 'secondary',
  'Commitment Signed': 'success',
  'Invoice sent': 'info',
  'Secured capital': 'primary',
};

export const imgSrc =
  'https://reviero.s3.eu-central-1.amazonaws.com/prod/notion/c17e87d3-394a-42c4-84c2-a61671cd48fb/89ed02b1dfb817df2bd2ea3b9ac6e170.webp';

export const _mockOverviewText = [
  'En fastighetsutvecklare uppför nu 14 bostadsrätter i radhusform i det naturnära villasamhället Velanda i Trollhättan. Det färdigställda projektet, som uppförs i totalt två etapper om 27 bostäder, har ett beräknat intäktsvärde om ca 59,6 mkr med en belåningsgrad om ca 60% (LTGDV). Den officiella säljstarten av etapp 1 planeras till januari 2024 i samband med att den första huskroppen har uppförts. Prisnivån för bostäderna ligger mellan 2 750 000 - 3 000 000 kr och fyra radhus har sålts innan säljstart.',
  'Den aktuella resningen delfinansierar den första etappen om tre radhus med totalt 14 bostäder som väntas tillträdas löpande fr.o.m. augusti 2024. Hittills har ca 26,8 mkr investerats i bland annat markförvärvet, projektering, förberedande markarbete, betalning av första huskroppen samt fönster till samtliga 14 bostäder. Pålning och plattgjutning är klart och den första huskroppen med fyra radhus har levererats till byggplatsen och beräknas vara rest och tätad i januari 2024. Fastighetsutvecklaren har tidigare finansierat ett bostadsrättsprojekt i Uddevalla via Tessin och samtliga fem lån om totalt 55 mkr återbetalades till investerarna under 2021/2022.',
  'Bolaget lånar nu in 8 mkr, med möjlighet till överteckning upp till 12 mkr, i en tredje av totalt fyra planerade kapitalresningar för att delfinansiera byggnationen. Lånet löper upp till 12 mån med 14% årsavkastning och säkerställs med pant i fastigheten, proprieborgen och begränsad personlig borgen. Bolagen som ställer proprieborgen för det aktuella erbjudandet redovisar gemensamt tillgångar om ca 60 mkr per 2023-06-30.',
];

export const _mockMarketplaceProjectReports: MarketplaceProjectReport[] = [
  {
    _id: '1',
    name: 'Full Financial Upraisal',
    createdAt: '20 May 2022',
    updatedAt: null,
    url: '',
    type: 'fin-upraisal',
    svgColor: '#266D7D',
    visibility: 'Published',
  },
  {
    _id: '2',
    name: 'Bank Report',
    createdAt: '20 May 2022',
    updatedAt: null,
    url: '',
    type: 'bank',
    svgColor: '#B1DDE7',
    visibility: 'Published',
  },
  {
    _id: '3',
    name: 'Construction Supplier Report',
    createdAt: '20 May 2022',
    updatedAt: '22 May 2022',
    url: '',
    type: 'supplier',
    svgColor: '#3698AF',
    visibility: 'Not published',
  },
];

export const MARKETPLACE_PROJECT_REPORTS_TABLE_HEAD = [
  {
    _id: 'name',
    label: 'Report Name',
  },
  {
    _id: 'createdAt',
    label: 'Date Created',
    width: 200,
  },
  {
    _id: 'updatedAt',
    label: 'Date Updated',
    width: 200,
  },
  {
    _id: 'published',
    label: 'Visibility',
    width: 200,
  },
  {
    _id: 'open',
    width: 140,
  },
];


export const _mockMarketplaceProjectFiles: MarketplaceProjectFile[] = [
  {
    _id: '0',
    filename: 'All in One',
    uploadedAt: '20 May 2022',
    uploadedBy: 'Artem Titov',
    requestedAt: '20 May 2022',
    requestedBy: 'Artem Titov',
    url: imgSrc,
    uploadingFileType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    section: 'Financing',
    size: 2048,
  },
  {
    _id: '1',
    filename: 'Full Financial Upraisal',
    requestedAt: '20 May 2022',
    requestedBy: 'Artem Titov',
    url: imgSrc,
    uploadingFileType: 'application/pdf',
    section: 'Tendering',
    size: 2048,
  },
  {
    _id: '2',
    filename: 'Project Teaser',
    uploadedAt: '20 May 2022',
    uploadedBy: 'Artem Titov',
    requestedAt: '20 May 2022',
    requestedBy: 'Artem Titov',
    url: imgSrc,
    uploadingFileType: 'application/pdf',
    section: 'Lead',
    size: 1024,
  },
  {
    _id: '3',
    filename: 'Proof of tax payments.pdf',
    uploadedAt: '20 May 2022',
    uploadedBy: 'Artem Titov',
    url: imgSrc,
    uploadingFileType: 'application/pdf',
    section: 'Lead',
    size: 1024,
  },
];

export const MARKETPLACE_PROJECT_FILES_TABLE_HEAD = [
  {
    _id: 'name',
    label: 'Name / Size',
  },
  {
    _id: 'section',
    label: 'Section',
    width: 120,
  },
  {
    _id: 'uploadedAt',
    label: 'Uploaded',
    width: 120,
  },
  {
    _id: 'requestedAt',
    label: 'Requested',
    width: 120,
  },
  {
    _id: 'controls',
    width: 172,
  },
];
