import Box from '@mui/material/Box';
import { Palette, useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { marketplaceProjectActivityStatusColor } from 'src/features/marketplace-details/components/project-details/mock-data';

import { MarketplaceProjectActivityStatusType } from '../../../../../../../../../types';

type StatusColorProps = {
  status: MarketplaceProjectActivityStatusType;
}

export const StatusColor = (props: StatusColorProps) => {
  const { status } = props;

  const theme = useTheme();
  const textSecondary = theme.palette.text.secondary;

  const statusColor = useMemo(() => {
    if (status === 'Archive') return textSecondary;
    const key = marketplaceProjectActivityStatusColor[status] as keyof Palette;
    const palette = theme.palette[key];
    return typeof palette === 'object' && 'main' in palette ? palette.main : textSecondary;
  }, [status, textSecondary, theme.palette]);

  return <Box
    sx={{
      borderRadius: '50%',
      width: 10,
      height: 10,
      background: statusColor,
    }}
  />;
};
