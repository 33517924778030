import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MapBox } from 'src/components';

const coords = [
  18.046_482_299_999_98,
  59.459_595_4,
];

const marker = {
  location: {
    longitude: coords[0],
    latitude: coords[1],
  },
  info: { name: 'Ski Resort Сomplex' },
};

export const OverviewLocation = () => {
  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant='h6'>Location</Typography>
          <MapBox
            height='449px'
            width='100%'
            mapStyle='street'
            markers={[marker]}
            coordinates={coords}
            zoom={16}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
