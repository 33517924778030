import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { CardRadialFilterBarChart } from 'src/components/card-radial-bar-chart/card-radial-filter-bar-chart';

import { MarketplaceOffer } from '../../../../../../../../types';

type OfferingTotalProps = {
  projects: MarketplaceOffer[];
  title?: string;
}

export const OfferingTotal = (props: OfferingTotalProps) => {
  const { projects, title } = props;
  const theme = useTheme();

  const additionalSeriesValues = useMemo(() => {
    const names = projects.map(item => item.name);
    names.unshift('All Projects');
    return names;
  }, [projects]);

  const generatedMockDataForRadialChart: {
    seriesFilter?: string;
    label: string;
    value: number;
  }[] = useMemo(() => {
    const radialData = [
      {
        seriesFilter: 'All Projects',
        label: 'Soft Committed',
        value: projects.reduce((acc, cur) => {
          return acc + (cur.softCommitted || 0);
        }, 0),
      },
      {
        seriesFilter: 'All Projects',
        label: 'Capital Secured',
        value: projects.reduce((acc, cur) => {
          return acc + (cur.secured || 0);
        }, 0),
      },
    ];
    projects.forEach(p => {
      radialData.push(
        {
          seriesFilter: p.name,
          label: 'Soft Committed',
          value: p.softCommitted || 0,
        },
        {
          seriesFilter: p.name,
          label: 'Capital Secured',
          value: p.secured || 0,
        },
      );
    });
    return radialData;
  }, [projects]);

  return (
    <CardRadialFilterBarChart
      seriesValues={additionalSeriesValues}
      title={title || 'Overview'}
      chartHeader='Total Required'
      chart={{
        colors: [
          [theme.palette.primary.main, theme.palette.primary.main],
          [theme.palette.primary.lighter, theme.palette.primary.lighter],
        ],
        series: generatedMockDataForRadialChart,
      }}
      showFilter={false}
    />
  );
};
