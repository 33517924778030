import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ListItemCustom } from 'src/components';

import { OverviewDetails } from './overview-details';
import { OverviewInfoAbout } from './overview-info-about';
import { OverviewLocation } from './overview-location';

type OverviewInfoProps =  {
  isPartner?: boolean;
}

export const OverviewInfo = (props: OverviewInfoProps) => {
  const { isPartner } = props;
  const theme = useTheme();

  return (
    <Stack direction='row' gap={3}>
      <Stack gap={3}>
        <OverviewInfoAbout />
        <OverviewLocation />
      </Stack>
      <Stack gap={3} sx={{ minWidth: 376 }}>
        <OverviewDetails />

        <Card>
          <CardContent>
            <Stack gap={3}>
              <Typography variant='h6'>Developer</Typography>
              <ListItemCustom
                showAvatar
                avatarSx={{ borderRadius: 1.5 }}
                avatarSrc='Lueilwitz and Sons'
                avatarAlt='Lueilwitz and Sons'
                primaryText='Lueilwitz and Sons'
                secondaryChildren={
                  <Link
                    underline='none'
                    target='_blank'
                    href='#'
                  >
                    Profile
                  </Link>
                }
              />
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack gap={3}>
              <Typography variant='h6'>Debt Financing</Typography>
              <ListItemCustom
                showAvatar
                avatarSx={{ borderRadius: 1.5 }}
                avatarSrc='Nordea'
                avatarAlt='Nordea'
                primaryText='Nordea'
                secondaryChildren={
                  <Link
                    underline='none'
                    target='_blank'
                    href='#'
                  >
                    Profile
                  </Link>
                }
              />
            </Stack>
          </CardContent>
        </Card>

        {!isPartner &&
          <Card>
            <CardContent>
              <ListItemCustom
                showAvatar
                avatarSrc='Max Smith'
                avatarAlt='Max Smith'
                primaryText='Max Smith'
                secondaryText='Navian Expert'
                secondaryTextVariant='caption'
                secondaryTextTypographySx={{ color: theme.palette.text.disabled }}
              />
            </CardContent>
          </Card>
        }
      </Stack>
    </Stack>
  );
};
