import { TableHeadType } from 'src/features/leads-page';

import { PartnerTransactionsTableFilters, PartnerTransactionType } from '../../../../../../../types';

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: 'name',
    label: 'Project Name / Type',
  },
  {
    _id: 'fee',
    label: 'Commission / Fee',
    width: 160,
    align: 'right',
  },
  {
    _id: 'investedAmount',
    label: 'Invested Amount',
    width: 160,
    align: 'right',
  },
  {
    _id: 'investor',
    label: 'Investor',
  },
  {
    _id: 'lastUpdatedAt',
    label: 'Last Update',
    width: 140,
  },
  {
    _id: 'status',
    label: 'Status',
    width: 140,
  },
];

export const defaultFilters: PartnerTransactionsTableFilters = { status: 'All' };

export const _mockPartnerTransactions: PartnerTransactionType[] = [
  {
    _id: '1',
    projectName: 'Ski Complex 1',
    projectType: 'New build houses and apartments',
    commission: 300_000,
    fee: 2,
    investedAmount: 3000,
    investorName: 'Jane Cooper',
    investorEmail: 'jane.cooper@mail.com',
    lastUpdatedAt: '12 Jun 2023',
    status: 'Pending',
  },
  {
    _id: '2',
    projectName: 'Ski Complex 2',
    projectType: 'New build houses and apartments',
    commission: 30_000,
    fee: 2,
    investedAmount: 3000,
    investorName: 'Jane Cooper',
    investorEmail: 'jane.cooper@mail.com',
    lastUpdatedAt: '12 Jun 2023',
    status: 'Sent',
  },
  {
    _id: '3',
    projectName: 'Ski Complex 3',
    projectType: 'New build houses and apartments',
    commission: 99_000,
    fee: 2,
    investedAmount: 3000,
    investorName: 'Jane Cooper',
    investorEmail: 'jane.cooper@mail.com',
    lastUpdatedAt: '12 Jun 2023',
    status: 'Pending',
  },
];
