import { alpha, SxProps, Theme, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { memo, SyntheticEvent, useMemo } from 'react';

import { LabelColor } from '../../../../types';
import { Label } from '../label';

export interface CustomTabProps {
  tabs: TabType[];
  defaultTabValue: string;
  currentTabValue: string;
  handleTabOnChange: (event: SyntheticEvent, value: string) => void;
  tabsSX?: SxProps<Theme>;
}

export type TabType = {
  value: string;
  label: string;
  color?: LabelColor;
  count?: number;
  hidden?: boolean;
  activeTabStyle?: boolean;
  disabled?: boolean;
}

export type LabelTabType = 'overview' | 'reports' | 'team' | 'files' | 'assets'
| 'suppliers' | 'financial' | 'offering' | 'tendering-request'
| 'financing-debt-request' | 'debt-financing-agreement' | 'sell-project'
| 'uploading-financing-request'
| 'raise-monitoring'

export const formsWithHiddenTabs = ['tendering-request', 'financing-debt-request', 'debt-financing-agreement', 'uploading-financing-request', 'sell-project'];

export const CustomTab = memo((props: CustomTabProps) => {
  const {
    tabs,
    defaultTabValue,
    currentTabValue,
    handleTabOnChange,
    tabsSX = {},
  } = props;
  const theme = useTheme();

  const getLabelVariant = (tabValue: string) => {
    return ((tabValue === defaultTabValue || tabValue === currentTabValue) && 'filled') || 'soft';
  };

  const defaultTabsSX = useMemo(() => {
    return {
      mr: 3,
      pl: 2.5,
      boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
    };
  }, [theme.palette.grey]);

  return (
    <Tabs
      value={currentTabValue}
      onChange={handleTabOnChange}
      sx={{ ...defaultTabsSX, ...tabsSX }}
    >
      {tabs.map((tab) => {
        if (tab.hidden) return null;
        return (
          <Tab
            key={tab.value}
            disabled={tab?.disabled}
            value={tab.value}
            label={tab.label}
            iconPosition='end'
            icon={
              <>
                {tab?.count ?
                  <Label
                    variant={tab?.activeTabStyle ? getLabelVariant(tab.value) : 'soft'}
                    color={tab?.color}
                    sx={{
                      backgroundColor: alpha(theme.palette.grey[500], 0.08),
                      borderRadius: '8px',
                      ml: 1,
                    }}
                  >
                    {tab?.count}
                  </Label>
                  : null
                }
              </>
            }
          />
        );
      })}
    </Tabs>
  );
});
