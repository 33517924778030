import {
  AlertCircleIcon,
  CalendarIcon,
  CaseIcon,
  ClockIcon,
  FilterIcon,
  FullscreenIcon,
  KeyIcon,
  LocationIcon,
  MailIcon,
  PhoneIcon,
  SearchIcon,
  UserCardIcon,
  UserIcon,
} from 'src/assets/svg';

const SearchSummaryIcon = <SearchIcon stroke='rgba(33, 43, 54, 1)'/>;

export const allSummaryItems = {
  'contactPerson': {
    label: 'Contact person',
    icon: <UserIcon/>,
  },
  'companyName': {
    label: 'Company name',
    icon: <CaseIcon/>,
  },
  'type': {
    label: 'Type',
    icon: <CaseIcon/>,
  },
  'location': {
    label: 'Location',
    icon: <LocationIcon/>,
  },
  'personPosition': {
    label: 'Contact person position',
    icon: <UserCardIcon/>,
  },
  'totalUnits': {
    label: 'Total units',
    icon: <KeyIcon/>,
  },
  'size': {
    label: 'Size',
    icon: <FullscreenIcon/>,
  },
  'mainTypeOfUnits': {
    label: 'Main type of units',
    icon: <FilterIcon/>,
  },
  'email': {
    label: 'Email',
    icon: <MailIcon/>,
  },
  'website': {
    label: 'Website',
    icon: <MailIcon/>,
  },
  'phone': {
    label: 'Phone number',
    icon: <PhoneIcon/>,
  },
  'source': {
    label: 'Source',
    icon: SearchSummaryIcon,
  },
  'createdBy': {
    label: 'Created by',
    icon: <UserIcon/>,
  },
  'dateCreated': {
    label: 'Date created',
    icon: <CalendarIcon/>,
  },
  'added': {
    label: 'Added',
    icon: <CalendarIcon/>,
  },
  'analyst': {
    label: 'Analyst',
    icon: <UserIcon/>,
  },
  'projectManager': {
    label: 'Project manager',
    icon: <UserIcon/>,
  },
  'rejected': {
    label: 'Rejected',
    icon: <AlertCircleIcon/>,
  },
  'projectType': {
    label: 'Project type',
    icon: <CaseIcon/>,
  },
  'phase': {
    label: 'Phase',
    icon: <ClockIcon/>,
  },
  'lastEdit': {
    label: 'Last Edit',
    icon: <FilterIcon/>,
  },
};
