import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DollarIcon, SpeedometerIcon, StarCircleIcon } from 'src/assets';
import { usePathname } from 'src/hooks';

const linkStyle = {
  borderRadius: '6px',
  padding: '6px',
  fontSize: '14px',
  lineHeight: '22px',
  textDecoration: 'none',
};

export const InvestorNav = () => {
  const theme = useTheme();
  const pathname = usePathname();

  const links = useMemo(() => [
    {
      href: '/investor/portfolio',
      label: 'My Portfolio',
      icon: <SpeedometerIcon />,
    },
    {
      href: '/investor/projects',
      label: 'Projects',
      icon: <StarCircleIcon />,
    },
    {
      href: '/investor/activity',
      label: 'Investment Activity',
      icon: <DollarIcon />,
    },
  ], []);

  return (
    <Stack direction='row' gap={1} alignItems='center' sx={{ margin: 'auto' }}>
      {links.map(link => {
        const isActive = pathname.startsWith(link.href);

        return (
          <Link
            to={link.href}
            style={{
              ...linkStyle,
              backgroundColor: isActive ? theme.palette.action.selected : '',
              fontWeight: isActive ? 600 : 500,
              color: isActive
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
            }}
          >
            <Stack direction='row' gap={1} alignItems='center'>
              {link.icon}
              <Typography variant='body2' color='text.primary'>{link.label}</Typography>
            </Stack>
          </Link>
        );
      })}
    </Stack>
  );
};
