import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { fNumber, fPercent } from 'src/utils';

export const OverviewDetails = () => {
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant='h6'>Proposal Details</Typography>
          <Stack gap={2}>
            <Stack direction='row' gap={3}>
              <Typography
                sx={{ minWidth: 145 }}
                variant='body2'
                color={theme.palette.text.secondary}
              >
                Total Required Capital
              </Typography>
              <Typography variant='subtitle2'>
                {fNumber(56_987_000)} {DEFAULT_CURRENCY}
              </Typography>
            </Stack>

            <Stack direction='row' gap={3}>
              <Typography
                sx={{ minWidth: 145 }}
                variant='body2'
                color={theme.palette.text.secondary}
              >
                Expected Returns
              </Typography>
              <Stack direction='row' gap={1}>
                <Typography variant='subtitle2'>
                  {fPercent(22)}
                </Typography>
                <Typography variant='subtitle2' color={theme.palette.text.disabled}>
                  •
                </Typography>
                <Typography variant='subtitle2'>
                  {fNumber(56_987_000)} {DEFAULT_CURRENCY}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction='row' gap={3}>
              <Typography
                sx={{ minWidth: 145 }}
                variant='body2'
                color={theme.palette.text.secondary}
              >
                Preferred Return
              </Typography>
              <Typography variant='subtitle2'>
                {fPercent(18)}
              </Typography>
            </Stack>

            <Stack direction='row' gap={3}>
              <Typography
                sx={{ minWidth: 145 }}
                variant='body2'
                color={theme.palette.text.secondary}
              >
                Possible Upside
              </Typography>
              <Typography variant='subtitle2'>
                {fNumber(12)}
              </Typography>
            </Stack>

            <Stack direction='row' gap={3}>
              <Typography
                sx={{ minWidth: 145 }}
                variant='body2'
                color={theme.palette.text.secondary}
              >
                Investment Type
              </Typography>
              <Typography variant='subtitle2'>
                Equity
              </Typography>
            </Stack>

            <Stack direction='row' gap={3}>
              <Typography
                sx={{ minWidth: 145 }}
                variant='body2'
                color={theme.palette.text.secondary}
              >
                Partner Fee
              </Typography>
              <Typography variant='subtitle2'>
                -
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
