import { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { ChangeEvent } from 'react';
import { CustomSelect, SearchField } from 'src/components';

export type TableToolbarProps<T, V, A> = {
  onFilters: (name: keyof T, value: V | V[] | A | A[] | string | string[] | null) => void;

  selectValue?: V[];
  selectOptions?: V[];
  selectedOptions?: V[];
  selectLabel?: string;
  selectKey?: keyof T;

  showAdditionalSelect?: boolean;
  additionalSelectValue?: A[];
  additionalSelectOptions?: A[];
  additionalSelectedOptions?: A[];
  additionalSelectLabel?: string;
  additionalSelectKey?: keyof T;

  searchFieldPlaceholder?: string;
  searchFieldValue: string;
  searchFieldKey: keyof T;
}

export const TableToolbar = <T, V, A, >(props: TableToolbarProps<T, V, A>) => {
  const {
    selectValue,
    selectOptions,
    onFilters,
    selectedOptions,
    selectLabel,
    selectKey,
    searchFieldPlaceholder = 'Search ...',
    searchFieldValue,
    searchFieldKey,
    showAdditionalSelect = false,
    additionalSelectedOptions,
    additionalSelectOptions,
    additionalSelectLabel,
    additionalSelectKey,
    additionalSelectValue,
  } = props;

  const handleFilterName = (event: ChangeEvent<HTMLInputElement>) => {
    onFilters(searchFieldKey, event.target.value as V);
  };

  const handleSelectOnChange = (filterKey: keyof T = selectKey!) => (event: SelectChangeEvent<V[] | A[]>) => {
    const targetValue: string | V[] | A[] = event.target.value;
    onFilters(
      filterKey,
      typeof targetValue === 'string'
        ? targetValue.split(',')
        : targetValue,
    );
  };

  return (
    <Stack
      spacing={2}
      alignItems={{
        xs: 'flex-end',
        md: 'center',
      }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ p: 2.5 }}
    >
      {selectKey &&
        <CustomSelect
          multiple
          showCheckbox
          controlSx={{
            width: {
              xs: 1,
              md: 292,
            },
          }}
          value={selectValue}
          handleSelectOnChange={handleSelectOnChange(selectKey)}
          label={selectLabel}
          options={selectOptions}
          selectedOptions={selectedOptions}
        />
      }
      {showAdditionalSelect &&
          <CustomSelect
            multiple
            showCheckbox
            controlSx={{
              width: {
                xs: 1,
                md: 292,
              },
            }}
            value={additionalSelectValue}
            handleSelectOnChange={handleSelectOnChange(additionalSelectKey)}
            label={additionalSelectLabel}
            options={additionalSelectOptions}
            selectedOptions={additionalSelectedOptions}
          />
      }

      <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <SearchField
          value={searchFieldValue}
          placeholder={searchFieldPlaceholder}
          handleFieldOnChange={handleFilterName}
        />
      </Stack>
    </Stack>
  );
};
