import { Grid, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import _, { debounce } from 'lodash';
import { ChangeEvent, memo, MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTab, TableCustom, TableFiltersResult, TablePaginationCustom, TableToolbar, TabType } from 'src/components';
import { paths } from 'src/config';
import { chipVariants, stageColor } from 'src/constants/constants';
import { useRouter, useTable } from 'src/hooks';
import { RootState } from 'src/store';
import { deleteReadyForInvestorThunk, loadReadyForInvestorList } from 'src/store/ready-for-investors';
import { sortCustom } from 'src/utils';

import { InvestorStatusListType, ProjectType, ReadyForInvestors } from '../../../../types';
import { _projectTypeList } from '../projects-offering-page';
import { ReadyForInvestorsRow } from './components';
import { defaultFilters, TABLE_HEAD } from './mock-data';
import { ReadyForInvestorParams, TableFilters, TableFilterValue } from './types';

export const ReadyForInvestorsPage = memo(() => {
  const [filters, setFilters] = useState(defaultFilters);
  const readyForInvestorsList = useSelector((state: RootState) => state.ReadyForInvestors.readyForInvestorsList);
  const isDemo = useSelector((state: RootState) => state.App.config?.isDemo);
  const [correspondingBody, setCorrespondingBody] = useState<ReadyForInvestorParams>({
    page: 1,
    limit: 5,
    status: 'All',
  });
  const table = useTable();
  const router = useRouter();
  const dispatch = useDispatch();

  const dataFiltered: ReadyForInvestors[] = isDemo ? applyFilter(sortCustom(readyForInvestorsList.projects, table), filters) : readyForInvestorsList.projects;
  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  useEffect(() => {
    dispatch(loadReadyForInvestorList({
      page: 1,
      status: 'All',
      limit: 5,
    }));
  }, [dispatch]);

  const getPreparationLen = useCallback((status: InvestorStatusListType) => {
    return readyForInvestorsList.projects?.filter((item) => item.status.includes(status))?.length;
  }, [readyForInvestorsList]);

  const TABS: TabType[] = useMemo(() => {
    return [
      {
        value: 'All',
        label: 'All',
        count: isDemo ? readyForInvestorsList.projects?.length : readyForInvestorsList.counter.All,
      },
      {
        value: 'No Shared',
        label: 'No Shared',
        count: isDemo ? getPreparationLen('No Shared') : readyForInvestorsList.counter['No Shared'],
      },
      {
        value: 'Shared',
        label: 'Shared',
        count: isDemo ? getPreparationLen('Shared') : readyForInvestorsList.counter['Shared'],
      },
      {
        value: 'Published',
        label: 'Published',
        count: isDemo ? getPreparationLen('Published') : readyForInvestorsList.counter['Published'],
      },
      {
        value: 'Raised',
        label: 'Raised',
        count: isDemo ? getPreparationLen('Raised') : readyForInvestorsList.counter['Raised'],
      },
    ];
  }, [getPreparationLen, isDemo, readyForInvestorsList.counter, readyForInvestorsList.projects?.length]);

  const updateReadyForInvestors = useCallback((newBodyPart: Partial<ReadyForInvestorParams>) => {
    const newBody: ReadyForInvestorParams = {
      ...correspondingBody,
      ...newBodyPart,
    };
    dispatch(loadReadyForInvestorList(newBody));
    setCorrespondingBody(newBody);
  }, [correspondingBody, dispatch]);

  const handleFilters = useCallback((name: keyof TableFilters, value: TableFilterValue) => {
    updateReadyForInvestors({
      [name]: value,
      page: 1,
      limit: 5,
    });
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, [table, updateReadyForInvestors]);

  const handleFilterStatus = useCallback((_: SyntheticEvent, newValue: string) => {
    handleFilters('status', newValue as InvestorStatusListType);
  }, [handleFilters]);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
    updateReadyForInvestors(defaultFilters);
  }, [updateReadyForInvestors]);

  const canReset =
    !!filters.text ||
    filters.projectType?.length > 0 ||
    filters.status !== 'All';

  const noDataFound = (dataFiltered?.length === 0 && canReset) || dataFiltered?.length === 0;

  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    updateReadyForInvestors({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updateReadyForInvestors({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const onFiltersDeleteHandler = useCallback((filterKey: keyof TableFilters, valueToDelete: TableFilterValue) => {
    const filterValue = filters[filterKey];
    let newValue = defaultFilters[filterKey];
    if (Array.isArray(filterValue)) {
      newValue = filters.projectType.filter((item) => item !== valueToDelete);
    }
    handleFilters(filterKey, newValue);
  }, [filters, handleFilters]);

  const handleDeleteRow = useCallback((id: string) => () => {
    dispatch(deleteReadyForInvestorThunk(id));
    table.onUpdatePageDeleteRow(dataInPage?.length);
  }, [dataInPage?.length, dispatch, table]);

  const onRowClickHandler = (id: string) => () => {
    router.push(paths.readyForInvestors.details(id, 'overview'));
  };

  const onSortChange = debounce((id: string) => {
    const {
      orderBy,
      order,
    } = table;
    const isAsc = orderBy === id && order === 'asc';
    updateReadyForInvestors({
      sort: id,
      sortType: isAsc ? 'desc' : 'asc',
    });
    table.onSort(id);
  }, 200);

  return (
    <Container maxWidth={false}>
      <Grid container style={{ marginBottom: '40px' }}>
        <Grid item xs={6}>
          <Typography variant='h4'>
            Equity
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <CustomTab
          tabs={TABS}
          defaultTabValue='All'
          currentTabValue={filters.status}
          handleTabOnChange={handleFilterStatus}
        />
        <TableToolbar
          selectValue={filters.projectType}
          selectOptions={_projectTypeList}
          selectedOptions={filters.projectType}
          onFilters={handleFilters}
          selectLabel='Project type'
          selectKey='projectType'
          searchFieldValue={filters.text}
          searchFieldKey='text'
        />
        {canReset && (
          <Stack
            sx={{
              pt: 1.5,
              paddingLeft: '20px',
              paddingBottom: '16px',
            }}
          >
            <TableFiltersResult
              filters={filters}
              defaultFilters={defaultFilters}
              onFiltersDeleteHandler={onFiltersDeleteHandler}
              onResetFilters={handleResetFilters}
              results={isDemo ? dataInPage?.length : readyForInvestorsList.metadata.total}
              chipVariants={chipVariants}
              chipColors={{ ...stageColor }}
            />
          </Stack>
        )}

        <TableCustom
          headLabels={TABLE_HEAD}
          table={table}
          rowCount={isDemo ? readyForInvestorsList.projects.length : readyForInvestorsList.metadata.total}
          noDataFound={noDataFound}
          onSort={onSortChange}
          bodyCells={(
            <>
              {(isDemo ? dataInPage : readyForInvestorsList.projects)?.map((row) => (
                <ReadyForInvestorsRow
                  key={row._id}
                  row={row}
                  onViewRow={onRowClickHandler(row._id)}
                  onDeleteRow={handleDeleteRow(row._id)}
                />
              ))}
            </>
          )}
        />

        <TablePaginationCustom
          count={isDemo ? dataFiltered.length : readyForInvestorsList.metadata.total}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
});

const applyFilter = (data: ReadyForInvestors[], filters: TableFilters) => {
  let resultData = _.cloneDeep(data);
  const {
    status,
    text,
    projectType,
  } = filters;

  if (text) {
    resultData = resultData?.filter((item) => {
      return item.project?.name?.toLowerCase().includes(text.toLowerCase()) ||
        item.project?.scenario?.calculate?.input?.type_of_project?.toString().includes(text.toLowerCase()) ||
        item.status?.toString().toLowerCase().includes(text.toLowerCase()) ||
        item.equityDistributionPartnersCount?.toString().includes(text) ||
        item.marketPublishesCount?.toString().includes(text) ||
        item.phase?.toLowerCase().toString().includes(text.toLowerCase());
    });
  }

  if (projectType?.length > 0) {
    resultData = resultData?.filter(item => projectType.includes(item.project?.scenario?.calculate?.input?.type_of_project as ProjectType));
  }

  if (status !== 'All') {
    resultData = resultData?.filter((item) => item.status.includes(status));
  }

  return resultData;
};
