import { StepType } from 'src/components';
import { sectionsList } from 'src/constants/constants';
import { demoFiles } from 'src/constants/demo-mock-data';

import { FilesTable } from '../../../../types';
import { BackLogItemType } from './components';

export const steps: StepType[] = [
  { label: 'Research' },
  { label: 'Project Preparation' },
  { label: 'Tendering' },
  { label: 'Financing' },
  { label: 'Distribution' },
];

export const _fileItems: FilesTable[] = demoFiles;
export const _mockBacklogItems: BackLogItemType[] = [
  {
    _id: '1',
    iconSrc: 'icon1.png',
    name: 'Victor Klintåker',
    isNew: true,
    lastAction: 'started a research',
    actionDate: '2020-09-07',
    section: 'Research',
    project: 'Navian',
  },
  {
    _id: '2',
    iconSrc: 'icon2.png',
    name: 'Eric Linton',
    isNew: false,
    lastAction: 'appointed a project manager',
    actionDate: '2024-09-07',
    section: 'Lead',
    project: 'Navian',
  },
  {
    _id: '3',
    iconSrc: 'icon3.png',
    name: 'Eric Linton',
    isNew: false,
    lastAction: 'appointed an analyst',
    actionDate: '2024-09-07',
    section: 'Lead',
    project: 'Navian',
  },
  {
    _id: '4',
    iconSrc: 'icon4.png',
    name: 'Charles Flores',
    isNew: false,
    lastAction: 'added a Lead',
    actionDate: '2024-09-07',
    section: 'Lead',
    project: 'Pareto',
  },
];

export const _mockProjects = ['All projects', 'Navian', 'Pareto'];
export const _mockSections = ['All sections', ...sectionsList];
