import { StepLabel } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import { LoadingIcon } from 'src/assets/svg';

import { StepIconRoot } from './step-icon-root';

export const StepIconComponent = (props: StepIconProps) => {
  const {
    active,
    completed,
  } = props;

  return (
    <StepIconRoot ownerState={{ active }} className={active ? 'active' : ''}>
      {completed ? (
        <StepLabel className='QontoStepIcon-completedIcon'/>
      ) : (
        <div className='QontoStepIcon-circle'>
          {active &&
                  <LoadingIcon/>
          }
        </div>
      )}
    </StepIconRoot>
  );
};
