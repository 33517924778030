import { Button, Link, List, SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useMemo, useState } from 'react';
import { CustomDialog, CustomSelect, ListItemCustom } from 'src/components';
import { paths } from 'src/config';
import { supplierCheckboxes } from 'src/features/partners-page';
import { useRouter } from 'src/hooks';

import { CompanyPartnerType, PartnerItemType } from '../../../../../../types';

export interface SuppliersModalProps {
  open: boolean;
  onClose: () => void;
  selected: PartnerItemType[];
  suppliers: PartnerItemType[];
  addSuppliers: (suppliers: PartnerItemType[]) => void;
  deleteSupplier: (id: string) => () => void;
}

export const SuppliersModal = (props: SuppliersModalProps) => {
  const {
    open,
    onClose,
    selected,
    suppliers,
    addSuppliers,
    deleteSupplier,
  } = props;

  const selectedIds = selected.map(s => s._id);
  const router = useRouter();
  const [type, setType] = useState<CompanyPartnerType | ''>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const onTypeChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as CompanyPartnerType;
    setType(value);
  };

  const handleFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const endIconOnClickHandler = () => {
    setSearchValue('');
  };

  const onCloseWrapper = () => {
    setSearchValue('');
    setType('');
    onClose();
  };

  const filteredSuppliers = useMemo(() => {
    if (!searchValue && !type) return suppliers;
    const searchLow = searchValue.trim().toLowerCase();

    const bySearch = searchLow ? suppliers.filter((doc) => doc.companyName?.trim().toLowerCase().includes(searchLow)) : suppliers;

    return type ? bySearch.filter((doc) => doc.companySubType?.includes(type)) : bySearch;
  }, [searchValue, type, suppliers]);

  const toggleSupplier = (s: PartnerItemType, isSelected: boolean) => {
    return () => {
      if (isSelected) {
        deleteSupplier(s._id)();
      } else {
        addSuppliers([s]);
      }
    };
  };

  const onSelectAllClick = () => {
    addSuppliers(filteredSuppliers);
  };

  const addSupplierClickHandler = () => {
    router.push(paths.supplierPartners.list);
  };

  return (
    <CustomDialog
      showCustomHead
      customHeadButtonTitle='Add Supplier'
      customHeadTitle='Construction Suppliers'
      customHeadBtnProps={{ onClick: addSupplierClickHandler }}
      customFilters={
        <CustomSelect
          controlSx={{ flex: 1 }}
          value={type ? [type] : []}
          handleSelectOnChange={onTypeChange}
          label='Type'
          options={supplierCheckboxes[0].checkboxGroup?.map(checkbox => checkbox.value)}
        />
      }
      searchFieldProps={{
        sx: { flex: 1 },
        value: searchValue,
        showEndIcon: Boolean(searchValue),
        endIconOnClickHandler: endIconOnClickHandler,
        handleFieldOnChange: handleFieldOnChange,
      }}
      dialogProps={{
        open,
        onClose: onCloseWrapper,
      }}
      dialogContentChildren={
        <>
          <List sx={{ p: 0 }}>
            {filteredSuppliers.map(supplier => {
              const isSelected = selectedIds.includes(supplier._id);

              return (
                <ListItemCustom
                  showBtnIcon
                  showAvatar
                  key={supplier._id}
                  avatarSrc={supplier.logoUrl}
                  avatarSx={{ borderRadius: 1.5 }}
                  avatarAlt={supplier.companyName}
                  primaryText={supplier.companyName}
                  secondaryText={supplier.companySubType?.join(', ')}
                  btnConfig={{ onClick: toggleSupplier(supplier, isSelected) }}
                  btnTitle={isSelected ? 'Selected' : 'Select'}
                  iconChangeClause={isSelected}
                  secondaryChildren={
                    <>
                      <Link
                        underline='none'
                        target='_blank'
                        href={paths.supplierPartners.products(supplier._id)}
                      >
                        Profile
                      </Link>
                    </>
                  }
                />
              );
            })}
          </List>
        </>
      }
      dialogActionsChildren={
        <>
          <Button
            fullWidth
            variant='outlined'
            onClick={onSelectAllClick}
          >
            Select All
          </Button>
        </>
      }
    />
  );
};
