import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DEFAULT_AREA } from 'src/constants/constants';
import { _mockOverviewText } from 'src/features/marketplace-details/components/project-details/mock-data';

export const OverviewInfoAbout = () => {
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant='h6'>About Project</Typography>

          <Stack direction='row' gap={1.5}>
            <Stack
              gap={0.5}
              sx={{
                borderRadius: 1,
                px: 2,
                py: 1,
                backgroundColor: theme.palette.background.neutral,
              }}
            >
              <Typography variant='body2' color={theme.palette.text.secondary}>
              Type
              </Typography>
              <Typography variant='subtitle2'>New build houses</Typography>
            </Stack>
            <Stack
              gap={0.5}
              sx={{
                borderRadius: 1,
                px: 2,
                py: 1,
                backgroundColor: theme.palette.background.neutral,
              }}
            >
              <Typography variant='body2' color={theme.palette.text.secondary}>
              Size
              </Typography>
              <Typography variant='subtitle2'>1200 {DEFAULT_AREA}</Typography>
            </Stack>
            <Stack
              gap={0.5}
              sx={{
                borderRadius: 1,
                px: 2,
                py: 1,
                backgroundColor: theme.palette.background.neutral,
              }}
            >
              <Typography variant='body2' color={theme.palette.text.secondary}>
              Location
              </Typography>
              <Typography variant='subtitle2'>Boden</Typography>
            </Stack>
            <Stack
              gap={0.5}
              sx={{
                borderRadius: 1,
                px: 2,
                py: 1,
                backgroundColor: theme.palette.background.neutral,
              }}
            >
              <Typography variant='body2' color={theme.palette.text.secondary}>
              Units
              </Typography>
              <Typography variant='subtitle2'>32</Typography>
            </Stack>
          </Stack>

          <Stack gap={3}>
            {_mockOverviewText.map((text, i) =>
              <Typography key={i} variant='body1'>
                {text}
              </Typography>,
            )}
          </Stack>

          <Stack gap={2}>
            <Typography variant='subtitle1'>Tags</Typography>
            <Stack direction='row' gap={1}>
              <Chip
                variant='soft'
                size='medium'
                color='default' label='Apartment House'
              />
              <Chip
                variant='soft'
                size='medium'
                color='default' label='Stockholm'
              />
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
