import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type { CSSProperties } from 'react';

const photosInnerSx = { flex: 1 };
const imgWrapSx = { position: 'relative' };
const imgInnerSx = {
  display: 'block',
  width: '100%',
  paddingTop: '100%',
};
const imgStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '12px',
};

type OverviewPhotosProps = {
  photos: string[];
};

export const OverviewPhotos = (props: OverviewPhotosProps) => {
  const { photos } = props;

  return (
    <Stack direction='row' gap={2}>
      <Box sx={photosInnerSx}>
        <Box sx={imgWrapSx}>
          <Box sx={imgInnerSx} />
          <img
            style={imgStyle}
            src={photos[0]}
            alt=''
            loading='lazy'
          />
        </Box>
      </Box>

      <Stack
        sx={photosInnerSx}
        direction='row'
        flexWrap='wrap'
        gap={2}
      >
        {photos.slice(1).map((item, i) => (
          <Box
            key={i}
            sx={{ ...imgWrapSx, width: 'calc(50% - 8px)' }}
          >
            <img
              style={imgStyle}
              src={item}
              alt=''
              loading='lazy'
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};
