import { TextFieldProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { DrawerCustom, Iconify, ListItemCustom, UploadedFilesType, UploadFile } from 'src/components';
import { paths } from 'src/config';
import { UseBooleanReturnType } from 'src/hooks';
import { RootState } from 'src/store';
import { fNumber, onlyNumbers } from 'src/utils';

import { CompanyPartnerType, PartnerItemType } from '../../../../../../../../types';

export type UploadFormDrawerSubmit = {
  costs: number;
  psqm: number;
  buildTimeMonths: number;
  feePercent: number;
  recalculateNote?: string;
  isCalculate?: boolean;
  file?: UploadedFilesType;
}

interface UploadFormDrawerProps {
  drawer: UseBooleanReturnType;
  onSubmit: (data: UploadFormDrawerSubmit) => void;
  supplier: PartnerItemType;
  title: string;
  requestType?: CompanyPartnerType;
  buttonText?: string;
  showIsCalculate?: boolean;
  defaultCosts?: number;
  defaultPsqm?: number;
  defaultBuildTimeMonths?: number;
  defaultFeePercent?: number;
}

export const UploadFormDrawer = (props: UploadFormDrawerProps) => {
  const {
    drawer,
    onSubmit,
    supplier,
    title,
    buttonText,
    requestType,
    showIsCalculate = false,
    defaultFeePercent = '',
    defaultBuildTimeMonths = '',
    defaultPsqm = '',
    defaultCosts = '',
  } = props;
  const theme = useTheme();

  const [costs, setCosts] = useState<string>(defaultCosts.toString());
  const [psqm, setPsqm] = useState<string>(defaultPsqm.toString());
  const [buildTime, setBuildTime] = useState<string>(defaultBuildTimeMonths.toString());
  const [fee, setFee] = useState<string>(defaultFeePercent.toString());
  const [isCalculate, setIsCalculate] = useState<boolean>(false);
  const [recalculateNote, setRecalculateNote] = useState<string>('');
  const [file, setFile] = useState<UploadedFilesType>();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const scenario = detailsData.project?.scenario;
  const onTextFieldChange = (setter: Dispatch<SetStateAction<string>>) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setter(onlyNumbers(e.currentTarget.value));
  };
  const isCostsAreEqual = scenario?.calculate?.input?.total_construction_cost === Number(costs);
  const isBuildTimeAreEqual = scenario?.calculate?.input?.build_time_months === Number(buildTime);
  const canSubmit = !!costs && !!psqm && !!buildTime && (requestType === 'Development Partner' ? !!fee : true) && (isCalculate ? !!recalculateNote : true);
  const isCalculateDisabled = isCostsAreEqual && isBuildTimeAreEqual;

  const onSubmitClick = () => {
    if (canSubmit) {
      const submitData: UploadFormDrawerSubmit = {
        costs: Number(costs),
        psqm: Number(psqm),
        feePercent: Number(fee),
        buildTimeMonths: Number(buildTime),
        file,
      };
      if (showIsCalculate) {
        submitData.isCalculate = isCalculate;
        submitData.recalculateNote = recalculateNote;
      }
      onSubmit(submitData);
    }
  };

  const toggleCheckbox = () => {
    setIsCalculate(!isCalculate);
  };

  const onRecalculateNoteChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setRecalculateNote(value);
  };

  return (
    <>
      <DrawerCustom
        open={drawer.value}
        onCloseDrawerHandler={drawer.onFalse}
        headChildren={
          <Stack
            width='100%'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <Typography variant='h6'>{title}</Typography>
            <IconButton
              onClick={drawer.onFalse}
            >
              <Iconify icon='mingcute:close-line' width={18}/>
            </IconButton>
          </Stack>
        }
        bodyChildren={
          <Stack gap={3}>
            <ListItemCustom
              showAvatar
              key={supplier._id}
              avatarSrc={supplier.logoUrl}
              avatarSx={{ borderRadius: 1.5 }}
              avatarAlt={supplier.companyName}
              primaryText={supplier.companyName}
              secondaryText={supplier.companyType}
              secondaryChildren={
                <Link
                  underline='none'
                  target='_blank'
                  href={paths.supplierPartners.products(supplier._id)}
                >
                  Profile
                </Link>
              }
            />
            {requestType &&
                <Autocomplete
                  options={[]}
                  disabled
                  value={requestType}
                  renderInput={(params) => (
                    <TextField
                      label='Services'
                      {...params as TextFieldProps}
                    />
                  )}
                />
            }
            <TextField
              required
              value={costs ? fNumber(costs) : ''}
              onChange={onTextFieldChange(setCosts)}
              label='Construction Costs'
              variant='outlined'
              fullWidth
            />
            <TextField
              required
              value={psqm ? fNumber(psqm) : ''}
              onChange={onTextFieldChange(setPsqm)}
              label='Construction Psqm'
              variant='outlined'
              fullWidth
            />
            <TextField
              required
              value={buildTime ? fNumber(buildTime) : ''}
              onChange={onTextFieldChange(setBuildTime)}
              label='Build time (months)'
              variant='outlined'
              fullWidth
            />
            {requestType === 'Development Partner' &&
                <TextField
                  required
                  value={fee ? fNumber(fee) : ''}
                  onChange={onTextFieldChange(setFee)}
                  label='Partner fee,%'
                  variant='outlined'
                  fullWidth
                />
            }
            {showIsCalculate &&
                <Stack
                  borderRadius={2}
                  p={2}
                  width='100%'
                  rowGap={2}
                  sx={{ backgroundColor: (theme) => isCalculate ? alpha(theme.palette.info.main, 0.08) : theme.palette.grey[100] }}
                >
                  <FormControlLabel control={
                    <Switch
                      disabled={isCalculateDisabled}
                      checked={isCalculate}
                      onClick={toggleCheckbox}
                    />
                  }
                  labelPlacement='end'
                  label='Recalculate the financial details of the project'
                  />
                  {isCalculate &&
                      <TextField
                        disabled={isCalculateDisabled}
                        multiline
                        rows={4}
                        value={recalculateNote}
                        onChange={onRecalculateNoteChangeHandler}
                        label='Note *'
                      />
                  }
                </Stack>
            }
            <UploadFile
              containerSx={{ width: '100%' }}
              innerContainerSx={{ p: 2.5 }}
              section='Tendering'
              uploadTitle='Upload file'
              titleStyleConfig={{
                variant: 'body2',
                color: theme.palette.text.disabled,
              }}
              uploadedFiles={file ? [file] : []}
              deleteFilesHandler={(f) => setFile(f.at(-1))}
              uploadFileHandler={(f) => setFile(f.at(-1))}
              fileIconSx={{ mr: 0 }}
              customIcon={
                <Iconify
                  icon='eva:cloud-upload-fill'
                  width={40}
                  color={theme.palette.text.disabled}
                />
              }
            />
          </Stack>
        }
        footerChildren={
          <Button
            onClick={onSubmitClick}
            disabled={!canSubmit}
            variant='contained'
            size='large'
            fullWidth
          >
            {buttonText || 'Confirm'}
          </Button>
        }
      />
    </>
  );
};
