import { Button, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnalyticsIcon } from 'src/assets/svg';
import { CrumbType, DetailView, generateSummaryField } from 'src/components';
import { paths } from 'src/config';
import { blueGradient } from 'src/constants/colors';
import { statusColor } from 'src/constants/constants';
import { RootState } from 'src/store';
import { loadLeadDetails } from 'src/store/lead';
import { fNumber, researchClickHelper } from 'src/utils';

export const ResearchDetails = () => {
  const { id } = useParams<{ id: string, }>();

  const theme = useTheme();
  const dispatch = useDispatch();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const isLoading = useSelector((state: RootState) => state.Storage.isLoading);
  const proverestUrl = useSelector((state: RootState) => state.App.config?.proverestUrl);

  const scenario = detailsData?.project?.scenario;
  const scenarioOutput = scenario?.calculate?.output;
  const lead = detailsData?.lead;

  useEffect(() => {
    dispatch(loadLeadDetails(id));
  }, [dispatch, id]);

  const crumbs: CrumbType[] = [
    {
      title: 'Opportunities',
      href: paths.research.list,
    },
    {
      title: 'Research',
      href: paths.research.list,
    },
    { title: lead?.location.address },
  ];

  const summaryItems = useMemo(() => {
    return generateSummaryField({
      location: lead?.location.address,
      totalUnits: fNumber(scenarioOutput?.['Total units']),
      size: fNumber(scenarioOutput?.['Residual land value']),
      mainTypeOfUnits: scenarioOutput?.['Main unit type'],
    });
  }, [lead?.location.address, scenarioOutput]);

  const researchOnClickHandler = useCallback(() => {
    researchClickHelper(proverestUrl, id, scenario);
  }, [scenario, proverestUrl, id]);

  return (
    <DetailView
      showToolbarBadge
      showOverviewCloud
      componentsViews={detailsData?.project?._id ? 'default' : 'lead'}
      customSummaryItemsOnOverview={summaryItems}
      crumbs={crumbs}
      showData={Boolean(scenario && !isLoading)}
      toolbarTitle={lead?.location.address}
      toolbarBadgeVariant='outlined'
      toolbarBadgeTitle={scenario?.status || 'Pending'}
      toolbarBadgeColor={statusColor[scenario?.status ?? 'Pending']}
      toolbarChildren={
        <Button
          variant='contained'
          onClick={researchOnClickHandler}
        >
          {!scenario?._id && 'To'} Research
        </Button>
      }
      overviewProps={{ activeStep: 1 }}
      cloudProps={{
        showCloud: lead?.status !== 'Approved' && lead?.status !== 'Rejected',
        cardSx: { background: blueGradient },
        avatar: <AnalyticsIcon/>,
        btnTitle: 'Continue Researching',
        title: 'Continue Research this lead on Calculator',
        subtitle: 'Everything is ready to analyse this project',
        titleColor: theme.palette.info.darker,
        subTitleColor: theme.palette.info.darker,
        onBtnClickHandler: researchOnClickHandler,
      }}
      noteSection='Research'
    />
  );
};
