import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { updateAsset } from 'src/store/storage';
import { fNumber, onlyNumbers } from 'src/utils';

import { AdditionalStatementsAssetType, InvestingAssetType, LegalDocumentsAssetType } from '../../../../../../types';
import { DefaultAssetTabProps } from './assets';

export const Investing = (props: DefaultAssetTabProps) => {
  const { debouncedUpdateAsset } = props;
  const theme = useTheme();
  const assets = useSelector((state: RootState) => state.Storage.detailsData.assets);
  const dispatch = useDispatch();

  const [minAmount, setMinAmount] = useState<number>(0);
  const [maxAmount, setMaxAmount] = useState<number>(0);

  const [makeViewing, setMakeViewing] = useState<boolean>(false);
  const [selfCertConfirm, setSelfCertConfirm] = useState<boolean>(false);

  const [disclaimerEnabled, setDisclaimerEnabled] = useState<boolean>(false);
  const [disclaimer, setDisclaimer] = useState<string>('');

  useEffect(() => {
    if (!disclaimerEnabled) {
      setDisclaimer('');
    }
  }, [disclaimerEnabled]);

  useEffect(() => {
    if (assets?._id) {
      setMinAmount(assets.investing?.minInvestmentAmount || 0);
      setMaxAmount(assets.investing?.maxInvestmentAmount || 0);
      setMakeViewing(Boolean(assets.investing?.legalDocuments?.isViewingAndUnderstandingOfferingMandatory));
      setSelfCertConfirm(Boolean(assets.investing?.legalDocuments?.isInvestorSelfDeclaration));
      setDisclaimerEnabled(Boolean(assets.investing?.additionalStatements?.disclaimerSection));
      setDisclaimer(assets.investing?.additionalStatements?.disclaimerNote || '');
    }
  }, [assets?._id, assets?.investing?.additionalStatements?.disclaimerNote, assets?.investing?.additionalStatements?.disclaimerSection, assets?.investing?.legalDocuments?.isInvestorSelfDeclaration, assets?.investing?.legalDocuments?.isViewingAndUnderstandingOfferingMandatory, assets?.investing?.maxInvestmentAmount, assets?.investing?.minInvestmentAmount]);

  const numbersOnChangeHandler = (setter: Dispatch<SetStateAction<number>>, keyInAsset: keyof InvestingAssetType) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(onlyNumbers(event.currentTarget.value));
    setter(value);
    if (assets?._id) {
      debouncedUpdateAsset({
        ...assets,
        investing: {
          ...assets?.investing,
          [keyInAsset]: value,
        },
      });
    }
  };

  const disclaimerOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setDisclaimer(value);
    if (assets?._id) {
      debouncedUpdateAsset({
        ...assets,
        investing: {
          ...assets?.investing,
          additionalStatements: {
            ...assets?.investing?.additionalStatements,
            disclaimerNote: value,
          },
        },
      });
    }
  };

  const legalDocumentsCheckboxOnChangeHandler = (setter: Dispatch<SetStateAction<boolean>>, keyInAsset: keyof LegalDocumentsAssetType) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setter(value);
    if (assets?._id) {
      dispatch(updateAsset({
        ...assets,
        investing: {
          ...assets?.investing,
          legalDocuments: {
            ...assets?.investing?.legalDocuments,
            [keyInAsset]: value,
          },
        },
      }));
    }
  };

  const additionalStatementsCheckboxOnChangeHandler = (setter: Dispatch<SetStateAction<boolean>>, keyInAsset: keyof AdditionalStatementsAssetType) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setter(value);
    if (assets?._id) {
      dispatch(updateAsset({
        ...assets,
        investing: {
          ...assets?.investing,
          additionalStatements: {
            ...assets?.investing?.additionalStatements,
            [keyInAsset]: value,
          },
        },
      }));
    }
  };


  return (
    <Stack gap={3} flex={1}>
      <Card>
        <CardHeader title='Configure Your Investment Questionnaire'/>
        <CardContent>
          <Stack direction='row' gap={3}>
            <TextField
              value={minAmount ? fNumber(minAmount) : ''}
              onChange={numbersOnChangeHandler(setMinAmount, 'minInvestmentAmount')}
              label='Minimum investment amount, KR'
              variant='outlined'
              fullWidth
            />
            <TextField
              value={maxAmount ? fNumber(maxAmount) : ''}
              onChange={numbersOnChangeHandler(setMaxAmount, 'maxInvestmentAmount')}
              label='Maximum investment amount, KR'
              variant='outlined'
              fullWidth
            />
          </Stack>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title='Legal Documents'/>
        <CardContent>
          <Stack gap={0.5}>
            <FormControlLabel
              sx={{
                m: 0,
                alignItems: 'flex-start',
              }}
              control={
                <Checkbox
                  checked={makeViewing}
                  onChange={legalDocumentsCheckboxOnChangeHandler(setMakeViewing, 'isViewingAndUnderstandingOfferingMandatory')}
                  size='medium'
                  color='primary'
                />
              }
              label={
                <Stack>
                  <Typography variant='subtitle2' sx={{
                    pt: 1,
                    pb: 1,
                  }}>
                    Make viewing and understanding the offering’s attachments mandatory
                  </Typography>
                  <Typography
                    variant='body2'
                    color={theme.palette.text.secondary}
                  >
                    This will automatically add a statements making sure the investor has seen and understood all the
                    marketplace listing attachments
                  </Typography>
                </Stack>
              }
            />

            <FormControlLabel
              sx={{ m: 0 }}
              control={
                <Checkbox
                  checked={selfCertConfirm}
                  onChange={legalDocumentsCheckboxOnChangeHandler(setSelfCertConfirm, 'isInvestorSelfDeclaration')}
                  size='medium'
                  color='primary'
                />
              }
              label={
                <Typography variant='subtitle2'>
                  Сonfirmation of Investor Self-Certification
                </Typography>
              }
            />
          </Stack>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title='Additional Statments'/>
        <CardContent>
          <Stack gap={3}>
            <FormControlLabel
              sx={{
                m: 0,
                alignItems: 'flex-start',
              }}
              control={
                <Checkbox
                  checked={disclaimerEnabled}
                  onChange={additionalStatementsCheckboxOnChangeHandler(setDisclaimerEnabled, 'disclaimerSection')}
                  size='medium'
                  color='primary'
                />
              }
              label={
                <Stack>
                  <Typography variant='subtitle2' sx={{
                    pt: 1,
                    pb: 1,
                  }}>
                    Disclaimer Section
                  </Typography>
                  <Typography
                    variant='body2'
                    color={theme.palette.text.secondary}
                  >
                    This disclaimer will be added to the investors subscription flow in the portal
                  </Typography>
                </Stack>
              }
            />
            {disclaimerEnabled &&
              <TextField
                value={disclaimer}
                onChange={disclaimerOnChangeHandler}
                label='Disclaimer'
                variant='outlined'
                sx={{
                  ml: 5,
                  mr: 3,
                }}
                minRows={4}
                multiline
              />
            }
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
