import { TextFieldProps } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactNode, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';


interface RHFAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  helperText?: ReactNode;
}

export const RHFAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(props: Omit<RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) => {
  const {
    name,
    label,
    placeholder,
    helperText,
    required,
    ...other
  } = props;
  const {
    control,
    setValue,
  } = useFormContext();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <Autocomplete
          {...field}
          onChange={(_, newValue) => setValue(name, newValue, { shouldValidate: true })}
          renderInput={(params) => (
            <TextField
              label={label}
              ref={ref}
              placeholder={placeholder}
              required={required}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params as TextFieldProps}
            />
          )}
          {...other}
        />
      )}
    />
  );
};
