import { yupResolver } from '@hookform/resolvers/yup';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CrossIcon } from 'src/assets';
import { DrawerCustom, FormProvider, RHFTextField } from 'src/components';
import { isEmailValid, isPhoneNumberValid } from 'src/utils';
import * as Yup from 'yup';

import { SellOfferType } from '../../../../../types';

export interface AddRaiseOfferProps {
  showDrawer?: boolean;
  headTitle?: string;
  drawerOnClose: VoidFunction;
  sendOfferClickHandler: (data: OmittedSellOfferType) => void;
}

export type OmittedSellOfferType = Omit<SellOfferType, '_id' | 'createdAt' | 'updatedAt'>;

export const AddRaiseOffer = (props: AddRaiseOfferProps) => {
  const {
    showDrawer,
    headTitle = 'Add Offer',
    drawerOnClose,
    sendOfferClickHandler,
  } = props;

  const OfferSchema: Yup.ObjectSchema<OmittedSellOfferType> = Yup.object().shape({
    fullname: Yup.string().required('Full name is required'),
    offerProjectPrice: Yup.number().required('Project price is required').moreThan(0, 'Project price must be greater than 0'),
    phone: Yup.string().required('Phone is required').test(
      'phone',
      'Invalid phone number format',
      (value) => isPhoneNumberValid(value)),
    note: Yup.string(),
    email: Yup.string().required('Email is required').test(
      'email',
      'Email must be a valid email address',
      (value) => value ? isEmailValid(value) : true,
    ),
  });

  const defaultValues: OmittedSellOfferType = useMemo(() => ({
    fullname: '',
    email: '',
    phone: '',
    note: '',
    offerProjectPrice: 0,
  }), []);


  const methods = useForm<OmittedSellOfferType>({
    resolver: yupResolver(OfferSchema),
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    sendOfferClickHandler(data);
  });

  return (
    <DrawerCustom
      open={showDrawer}
      onCloseDrawerHandler={drawerOnClose}
      headChildren={(
        <Stack direction='row' justifyContent='space-between' width='100%' mb={3}>
          <Typography variant='h6'>{headTitle}</Typography>
          <IconButton onClick={drawerOnClose}>
            <CrossIcon/>
          </IconButton>
        </Stack>
      )}
      bodyChildren={(
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack sx={{
            gap: 3,
            height: '100%',
          }}>
            <Typography variant='subtitle2'>Contact Info</Typography>
            <RHFTextField
              fullWidth
              name='fullname'
              label='* Full Name'
            />
            <RHFTextField
              fullWidth
              name='email'
              label='* Email'
            />
            <RHFTextField
              fullWidth
              name='phone'
              label='* Phone'
            />
            <Typography variant='subtitle2'>Offer</Typography>
            <RHFTextField
              fullWidth
              type='number'
              name='offerProjectPrice'
              label='* Project Price'
            />
            <RHFTextField
              fullWidth
              multiline
              rows={4}
              name='note'
              label='Note'
            />
            <Button
              fullWidth
              variant='contained'
              type='submit'
              size='large'
              sx={{ mt: 'auto' }}
            >
              Send
            </Button>
          </Stack>
        </FormProvider>
      )}
      headChildrenSx={{
        flexDirection: 'column',
        pb: 0,
      }}
      bodyChildrenSx={{ height: '100%' }}
    />
  );
};
